export const CUSTOMER_SUPPORT = {
    ar: {
        AE: '8000 3570 4294',
        OM: '966 112246311',
        QA: '800 100 929',
        BH: '966 112246311',
        KW: '966 112246311',
        SA: '966 112246311'
    },
    en: {
        AE: '04 875 4545',
        OM: '966 112246311',
        QA: '800 100 929',
        BH: '966 112246311',
        KW: '966 112246311',
        SA: '966 112246311'
    }
}
