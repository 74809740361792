import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const OutsideClickHandler = ({ children, onOutsideClick, disabled, className, ...otherProps }) => {
    const node = useRef()
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return
        } else {
            onOutsideClick()
        }
    }

    useEffect(() => {
        !disabled && document.addEventListener('mousedown', handleClick, false)
        !disabled && document.addEventListener('keydown', handleClick, false)

        return () => {
            document.removeEventListener('mousedown', handleClick, false)
            document.removeEventListener('keydown', handleClick, false)
        }
    })

    return (
        <div className={className} {...otherProps} ref={node}>
            {children}
        </div>
    )
}

OutsideClickHandler.propTypes = {
    children: PropTypes.node.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

OutsideClickHandler.defaultProps = {
    onOutsideClick: () => {},
    disabled: false
}

export default OutsideClickHandler
