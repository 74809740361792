import { useState, useEffect } from 'react'

const useModal = initialState => {
    const [isShowing, setIsShowing] = useState(initialState ? initialState : false)

    function toggle() {
        setIsShowing(!isShowing)
    }

    useEffect(() => {
        if (isShowing) {
            document.body.classList.add('o-hidden')
        } else {
            document.body.classList.remove('o-hidden')
        }
    }, [isShowing])

    return {
        isShowing,
        toggle
    }
}

export default useModal
