import axios from 'axios'
import { getCookie, isEmpty, resolveKeysInObjectsArrays } from 'utils/browserHelper'
import { DOMAIN } from 'constants/base'
import { invalidSession } from './logError'
export const apiPostHelper = async (URL, PAYLOAD, contentType, requestHeaders) => {
    try {
        let contentTypeText = 'application/json; charset=UTF-8, text/html'
        if (contentType) {
            contentTypeText = contentType
        }
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            'app-agent': 'DESKTOP',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }
        const responseData = await axios.post(URL, PAYLOAD, {
            headers
        })
        return responseData
    } catch (e) {
       invalidSession(e)
        return {
            error: e
        }
    }
}

export const apiPostConfigHelper = async (URL, PAYLOAD, contentType, requestHeaders, config = {}) => {
    try {
        let contentTypeText = 'application/json; charset=UTF-8, text/html'
        if (contentType) {
            contentTypeText = contentType
        }
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }
        const responseData = await axios.post(URL, PAYLOAD, {
            headers,
            ...config
        })
        return responseData
    } catch (e) {
        invalidSession(e)
        return {
            error: e
        }
    }
}

export const apiPostFormHelper = async (URL, PAYLOAD) => {
    try {
        const responseData = await axios.post(URL, PAYLOAD, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0'
            }
        })
        return responseData
    } catch (e) {
        invalidSession(e)
        return e
    }
}

export const apiGetHelper = async (URL, contentType, requestHeaders = {}) => {
    try {
        let contentTypeText = 'text/plain;charset=UTF-8'
        if (contentType) {
            contentTypeText = contentType
        }

        let headers = {
            Accept: 'application/json',
            // Referer: 'https://www.cleartrip.com',
            r_lang: getCookie('lp') || '',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            'app-agent': 'DESKTOP',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }

        const responseData = await axios.get(URL, {
            headers
        })
        return responseData
    } catch (e) {
        invalidSession(e)
        return e
    }
}

export const apiGetConfigHelper = async (URL, contentType, requestHeaders, config = {}) => {
    try {
        let contentTypeText = 'text/plain;charset=UTF-8'
        if (contentType) {
            contentTypeText = contentType
        }

        let headers = {
            Accept: 'application/json',
            r_lang: getCookie('lp') || '',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            'app-agent': 'DESKTOP',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }

        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }

        const responseData = await axios.get(URL, {
            headers,
            ...config
        })
        return responseData
    } catch (e) {
        invalidSession(e)
        return e
    }
}

export const domain = resolveKeysInObjectsArrays(['location', 'origin'], window)

export const getWalletInfo = timeStamp => {
    try {
        //   const URL = `https://qa2.cleartrip.com/pay/wallet?userId=${userId}&currency=${currency}`
        const URL = `${DOMAIN}/partial/account/wallet?_=${timeStamp}`
        // const URL = `${DOMAIN}/pay/wallet?userId=${userId}&currency=${currency}`
        const header = {
            caller: domain
        }
        const response = apiGetHelper(URL, 'application/json', header)
        return response
    } catch (e) {
        console.error(e)
        logError(e)
    }
}

export const apiGetParamsHelper = async (URL, params, contentType) => {
    try {
        let contentTypeText = 'text/plain;charset=UTF-8'
        if (contentType) {
            contentTypeText = contentType
        }

        const responseData = await axios.get(URL, {
            headers: {
                Accept: 'application/json',
                r_lang: getCookie('lp') || '',
                'Content-Type': contentTypeText,
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0'
            },
            params
        })
        return responseData
    } catch (e) {
        invalidSession(e)
        // return e
    }
}

export const apiGetCurrency = async (URL) => {
    const ctAb = isEmpty(getCookie('ct-ab')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-ab')))
    let retries = ctAb['new_srp'] === 'b' ? 3 : 1
    for (let attempt = 1; attempt <= retries; attempt++) {
        try {
            const responseData = await axios.get(URL, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'text/plain;charset=UTF-8',
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0'
                }
            })
            return responseData
        } catch (e) {
            if (typeof newrelic == 'object') {
                newrelic.addPageAction('currency-api-failure', {
                    errorStack: e?.t0?.stack || '',
                    attempt,
                    retries
                })
            }
            if (attempt === retries) {
                invalidSession(e)
                // return e
            }
        }
    }
}

export const apiPutHelper = async (URL, PAYLOAD, contentType, requestHeaders) => {
    try {
        let contentTypeText = 'application/json; charset=UTF-8, text/html'
        if (contentType) {
            contentTypeText = contentType
        }
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }
        const responseData = await axios.put(URL, PAYLOAD, {
            headers
        })
        return responseData
    } catch (e) {
        invalidSession(e)
        return {
            error: e
        }
    }
}

export const apiDeleteHelper = async (URL, contentType, requestHeaders) => {
    try {
        let contentTypeText = 'text/plain;charset=UTF-8'
        if (contentType) {
            contentTypeText = contentType
        }

        let headers = {
            Accept: 'application/json',
            r_lang: getCookie('lp') || '',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }

        const responseData = await axios.delete(URL, {
            headers
        })
        return responseData
    } catch (e) {
        invalidSession(e)
        return e
    }
}
