import React from 'react'
import FlightRoutes from 'router/FlightRoutes'

function RootRouter(props) {
    return (
        <>
            <FlightRoutes />
        </>
    )
}

export default RootRouter
