export const POPULAR_MOBILE_COUNTRY = ['IN', 'AE', 'SA']
export const MOBILE_COUNTRY_CODES = {
    AF: { name: 'Afghanistan', code: '93', min: 5, max: 15 },
    AL: { name: 'Albania', code: '355', min: 5, max: 15 },
    DZ: { name: 'Algeria', code: '213', min: 5, max: 15 },
    AS: { name: 'American Samoa', code: '1-684', min: 5, max: 15 },
    AD: { name: 'Andorra', code: '376', min: 5, max: 15 },
    AO: { name: 'Angola', code: '244', min: 5, max: 15 },
    AI: { name: 'Anguilla', code: '1-264', min: 5, max: 15 },
    AQ: { name: 'Antarctica', code: '672', min: 5, max: 15 },
    AG: { name: 'Antigua and Barbuda', code: '1-268', min: 5, max: 15 },
    AR: { name: 'Argentina', code: '54', min: 5, max: 15 },
    AM: { name: 'Armenia', code: '374', min: 5, max: 15 },
    AW: { name: 'Aruba', code: '297', min: 5, max: 15 },
    AU: { name: 'Australia', code: '61', min: 5, max: 15 },
    AT: { name: 'Austria', code: '43', min: 5, max: 15 },
    AZ: { name: 'Azerbaijan', code: '994', min: 5, max: 15 },
    BS: { name: 'Bahamas', code: '1-242', min: 5, max: 15 },
    BH: { name: 'Bahrain', code: '973', min: 7, max: 8 },
    BD: { name: 'Bangladesh', code: '880', min: 5, max: 15 },
    BB: { name: 'Barbados', code: '1-246', min: 5, max: 15 },
    BY: { name: 'Belarus', code: '375', min: 5, max: 15 },
    BE: { name: 'Belgium', code: '32', min: 5, max: 15 },
    BZ: { name: 'Belize', code: '501', min: 5, max: 15 },
    BJ: { name: 'Benin', code: '229', min: 5, max: 15 },
    BM: { name: 'Bermuda', code: '1-441', min: 5, max: 15 },
    BT: { name: 'Bhutan', code: '975', min: 5, max: 15 },
    BO: { name: 'Bolivia', code: '591', min: 5, max: 15 },
    BA: { name: 'Bosnia and Herzegovina', code: '387', min: 5, max: 15 },
    BW: { name: 'Botswana', code: '267', min: 5, max: 15 },
    BR: { name: 'Brazil', code: '55', min: 5, max: 15 },
    IO: { name: 'British Indian Ocean Territory', code: '246', min: 5, max: 15 },
    VG: { name: 'British Virgin Islands', code: '1-284', min: 5, max: 15 },
    BN: { name: 'Brunei', code: '673', min: 5, max: 15 },
    BG: { name: 'Bulgaria', code: '359', min: 5, max: 15 },
    BF: { name: 'Burkina Faso', code: '226', min: 5, max: 15 },
    BI: { name: 'Burundi', code: '257', min: 5, max: 15 },
    KH: { name: 'Cambodia', code: '855', min: 5, max: 15 },
    CM: { name: 'Cameroon', code: '237', min: 5, max: 15 },
    CA: { name: 'Canada', code: '1', min: 5, max: 15 },
    CV: { name: 'Cape Verde', code: '238', min: 5, max: 15 },
    KY: { name: 'Cayman Islands', code: '1-345', min: 5, max: 15 },
    CF: { name: 'Central African Republic', code: '236', min: 5, max: 15 },
    TD: { name: 'Chad', code: '235', min: 5, max: 15 },
    CL: { name: 'Chile', code: '56', min: 5, max: 15 },
    CN: { name: 'China', code: '86', min: 5, max: 15 },
    CX: { name: 'Christmas Island', code: '61', min: 5, max: 15 },
    CC: { name: 'Cocos Islands', code: '61', min: 5, max: 15 },
    CO: { name: 'Colombia', code: '57', min: 5, max: 15 },
    KM: { name: 'Comoros', code: '269', min: 5, max: 15 },
    CK: { name: 'Cook Islands', code: '682', min: 5, max: 15 },
    CR: { name: 'Costa Rica', code: '506', min: 5, max: 15 },
    HR: { name: 'Croatia', code: '385', min: 5, max: 15 },
    CW: { name: 'Curacao', code: '599', min: 5, max: 15 },
    CY: { name: 'Cyprus', code: '357', min: 5, max: 15 },
    CZ: { name: 'Czech Republic', code: '420', min: 5, max: 15 },
    CD: { name: 'Democratic Republic of the Congo', code: '243', min: 5, max: 15 },
    DK: { name: 'Denmark', code: '45', min: 5, max: 15 },
    DJ: { name: 'Djibouti', code: '253', min: 5, max: 15 },
    DM: { name: 'Dominica', code: '1-767', min: 5, max: 15 },
    DO: { name: 'Dominican Republic', code: '1-809, 1-829, 1-849', min: 5, max: 15 },
    TL: { name: 'East Timor', code: '670', min: 5, max: 15 },
    EC: { name: 'Ecuador', code: '593', min: 5, max: 15 },
    EG: { name: 'Egypt', code: '20', min: 5, max: 15 },
    SV: { name: 'El Salvador', code: '503', min: 5, max: 15 },
    GQ: { name: 'Equatorial Guinea', code: '240', min: 5, max: 15 },
    ER: { name: 'Eritrea', code: '291', min: 5, max: 15 },
    EE: { name: 'Estonia', code: '372', min: 5, max: 15 },
    ET: { name: 'Ethiopia', code: '251', min: 5, max: 15 },
    FK: { name: 'Falkland Islands', code: '500', min: 5, max: 15 },
    FO: { name: 'Faroe Islands', code: '298', min: 5, max: 15 },
    FJ: { name: 'Fiji', code: '679', min: 5, max: 15 },
    FI: { name: 'Finland', code: '358', min: 5, max: 15 },
    FR: { name: 'France', code: '33', min: 5, max: 15 },
    PF: { name: 'French Polynesia', code: '689', min: 5, max: 15 },
    GA: { name: 'Gabon', code: '241', min: 5, max: 15 },
    GM: { name: 'Gambia', code: '220', min: 5, max: 15 },
    GE: { name: 'Georgia', code: '995', min: 5, max: 15 },
    DE: { name: 'Germany', code: '49', min: 5, max: 15 },
    GH: { name: 'Ghana', code: '233', min: 5, max: 15 },
    GI: { name: 'Gibraltar', code: '350', min: 5, max: 15 },
    GR: { name: 'Greece', code: '30', min: 5, max: 15 },
    GL: { name: 'Greenland', code: '299', min: 5, max: 15 },
    GD: { name: 'Grenada', code: '1-473', min: 5, max: 15 },
    GU: { name: 'Guam', code: '1-671', min: 5, max: 15 },
    GT: { name: 'Guatemala', code: '502', min: 5, max: 15 },
    GG: { name: 'Guernsey', code: '44-1481', min: 5, max: 15 },
    GN: { name: 'Guinea', code: '224', min: 5, max: 15 },
    GW: { name: 'Guinea-Bissau', code: '245', min: 5, max: 15 },
    GY: { name: 'Guyana', code: '592', min: 5, max: 15 },
    HT: { name: 'Haiti', code: '509', min: 5, max: 15 },
    HN: { name: 'Honduras', code: '504', min: 5, max: 15 },
    HK: { name: 'Hong Kong', code: '852', min: 5, max: 15 },
    HU: { name: 'Hungary', code: '36', min: 5, max: 15 },
    IS: { name: 'Iceland', code: '354', min: 5, max: 15 },
    IN: { name: 'India', code: '91', min: 10, max: 10 },
    TEST: { name: 'India', code: '91', min: 10, max: 10 },
    ID: { name: 'Indonesia', code: '62', min: 5, max: 15 },
    IQ: { name: 'Iraq', code: '964', min: 5, max: 15 },
    IE: { name: 'Ireland', code: '353', min: 5, max: 15 },
    IM: { name: 'Isle of Man', code: '44-1624', min: 5, max: 15 },
    IL: { name: 'Israel', code: '972', min: 5, max: 15 },
    IT: { name: 'Italy', code: '39', min: 5, max: 15 },
    CI: { name: 'Ivory Coast', code: '225', min: 5, max: 15 },
    JM: { name: 'Jamaica', code: '1-876', min: 5, max: 15 },
    JP: { name: 'Japan', code: '81', min: 5, max: 15 },
    JE: { name: 'Jersey', code: '44-1534', min: 5, max: 15 },
    JO: { name: 'Jordan', code: '962', min: 5, max: 15 },
    KZ: { name: 'Kazakhstan', code: '7', min: 5, max: 15 },
    KE: { name: 'Kenya', code: '254', min: 5, max: 15 },
    KI: { name: 'Kiribati', code: '686', min: 5, max: 15 },
    XK: { name: 'Kosovo', code: '383', min: 5, max: 15 },
    KW: { name: 'Kuwait', code: '965', min: 7, max: 8 },
    KG: { name: 'Kyrgyzstan', code: '996', min: 5, max: 15 },
    LA: { name: 'Laos', code: '856', min: 5, max: 15 },
    LV: { name: 'Latvia', code: '371', min: 5, max: 15 },
    LB: { name: 'Lebanon', code: '961', min: 5, max: 15 },
    LS: { name: 'Lesotho', code: '266', min: 5, max: 15 },
    LR: { name: 'Liberia', code: '231', min: 5, max: 15 },
    LY: { name: 'Libya', code: '218', min: 5, max: 15 },
    LI: { name: 'Liechtenstein', code: '423', min: 5, max: 15 },
    LT: { name: 'Lithuania', code: '370', min: 5, max: 15 },
    LU: { name: 'Luxembourg', code: '352', min: 5, max: 15 },
    MO: { name: 'Macau', code: '853', min: 5, max: 15 },
    MK: { name: 'Macedonia', code: '389', min: 5, max: 15 },
    MG: { name: 'Madagascar', code: '261', min: 5, max: 15 },
    MW: { name: 'Malawi', code: '265', min: 5, max: 15 },
    MY: { name: 'Malaysia', code: '60', min: 5, max: 15 },
    MV: { name: 'Maldives', code: '960', min: 5, max: 15 },
    ML: { name: 'Mali', code: '223', min: 5, max: 15 },
    MT: { name: 'Malta', code: '356', min: 5, max: 15 },
    MH: { name: 'Marshall Islands', code: '692', min: 5, max: 15 },
    MR: { name: 'Mauritania', code: '222', min: 5, max: 15 },
    MU: { name: 'Mauritius', code: '230', min: 5, max: 15 },
    YT: { name: 'Mayotte', code: '262', min: 5, max: 15 },
    MX: { name: 'Mexico', code: '52', min: 5, max: 15 },
    FM: { name: 'Micronesia', code: '691', min: 5, max: 15 },
    MD: { name: 'Moldova', code: '373', min: 5, max: 15 },
    MC: { name: 'Monaco', code: '377', min: 5, max: 15 },
    MN: { name: 'Mongolia', code: '976', min: 5, max: 15 },
    ME: { name: 'Montenegro', code: '382', min: 5, max: 15 },
    MS: { name: 'Montserrat', code: '1-664', min: 5, max: 15 },
    MA: { name: 'Morocco', code: '212', min: 5, max: 15 },
    MZ: { name: 'Mozambique', code: '258', min: 5, max: 15 },
    MM: { name: 'Myanmar', code: '95', min: 5, max: 15 },
    NA: { name: 'Namibia', code: '264', min: 5, max: 15 },
    NR: { name: 'Nauru', code: '674', min: 5, max: 15 },
    NP: { name: 'Nepal', code: '977', min: 5, max: 15 },
    NL: { name: 'Netherlands', code: '31', min: 5, max: 15 },
    AN: { name: 'Netherlands Antilles', code: '599', min: 5, max: 15 },
    NC: { name: 'New Caledonia', code: '687', min: 5, max: 15 },
    NZ: { name: 'New Zealand', code: '64', min: 5, max: 15 },
    NI: { name: 'Nicaragua', code: '505', min: 5, max: 15 },
    NE: { name: 'Niger', code: '227', min: 5, max: 15 },
    NG: { name: 'Nigeria', code: '234', min: 5, max: 15 },
    NU: { name: 'Niue', code: '683', min: 5, max: 15 },
    MP: { name: 'Northern Mariana Islands', code: '1-670', min: 5, max: 15 },
    NO: { name: 'Norway', code: '47', min: 5, max: 15 },
    OM: { name: 'Oman', code: '968', min: 8, max: 8 },
    PK: { name: 'Pakistan', code: '92', min: 5, max: 15 },
    PW: { name: 'Palau', code: '680', min: 5, max: 15 },
    PS: { name: 'Palestine', code: '970', min: 5, max: 15 },
    PA: { name: 'Panama', code: '507', min: 5, max: 15 },
    PG: { name: 'Papua New Guinea', code: '675', min: 5, max: 15 },
    PY: { name: 'Paraguay', code: '595', min: 5, max: 15 },
    PE: { name: 'Peru', code: '51', min: 5, max: 15 },
    PH: { name: 'Philippines', code: '63', min: 5, max: 15 },
    PN: { name: 'Pitcairn', code: '64', min: 5, max: 15 },
    PL: { name: 'Poland', code: '48', min: 5, max: 15 },
    PT: { name: 'Portugal', code: '351', min: 5, max: 15 },
    PR: { name: 'Puerto Rico', code: '1-787, 1-939', min: 5, max: 15 },
    QA: { name: 'Qatar', code: '974', min: 8, max: 8 },
    CG: { name: 'Republic of the Congo', code: '242', min: 5, max: 15 },
    RE: { name: 'Reunion', code: '262', min: 5, max: 15 },
    RO: { name: 'Romania', code: '40', min: 5, max: 15 },
    RU: { name: 'Russia', code: '7', min: 5, max: 15 },
    RW: { name: 'Rwanda', code: '250', min: 5, max: 15 },
    BL: { name: 'Saint Barthelemy', code: '590', min: 5, max: 15 },
    SH: { name: 'Saint Helena', code: '290', min: 5, max: 15 },
    KN: { name: 'Saint Kitts and Nevis', code: '1-869', min: 5, max: 15 },
    LC: { name: 'Saint Lucia', code: '1-758', min: 5, max: 15 },
    MF: { name: 'Saint Martin', code: '590', min: 5, max: 15 },
    PM: { name: 'Saint Pierre and Miquelon', code: '508', min: 5, max: 15 },
    VC: { name: 'Saint Vincent and the Grenadines', code: '1-784', min: 5, max: 15 },
    WS: { name: 'Samoa', code: '685', min: 5, max: 15 },
    SM: { name: 'San Marino', code: '378', min: 5, max: 15 },
    ST: { name: 'Sao Tome and Principe', code: '239', min: 5, max: 15 },
    SA: { name: 'Saudi Arabia', code: '966', min: 9, max: 10 },
    SN: { name: 'Senegal', code: '221', min: 5, max: 15 },
    RS: { name: 'Serbia', code: '381', min: 5, max: 15 },
    SC: { name: 'Seychelles', code: '248', min: 5, max: 15 },
    SL: { name: 'Sierra Leone', code: '232', min: 5, max: 15 },
    SG: { name: 'Singapore', code: '65', min: 5, max: 15 },
    SX: { name: 'Sint Maarten', code: '1-721', min: 5, max: 15 },
    SK: { name: 'Slovakia', code: '421', min: 5, max: 15 },
    SI: { name: 'Slovenia', code: '386', min: 5, max: 15 },
    SB: { name: 'Solomon Islands', code: '677', min: 5, max: 15 },
    SO: { name: 'Somalia', code: '252', min: 5, max: 15 },
    ZA: { name: 'South Africa', code: '27', min: 5, max: 15 },
    KR: { name: 'South Korea', code: '82', min: 5, max: 15 },
    SS: { name: 'South Sudan', code: '211', min: 5, max: 15 },
    ES: { name: 'Spain', code: '34', min: 5, max: 15 },
    LK: { name: 'Sri Lanka', code: '94', min: 5, max: 15 },
    SR: { name: 'Suriname', code: '597', min: 5, max: 15 },
    SJ: { name: 'Svalbard and Jan Mayen', code: '47', min: 5, max: 15 },
    SZ: { name: 'Swaziland', code: '268', min: 5, max: 15 },
    SE: { name: 'Sweden', code: '46', min: 5, max: 15 },
    CH: { name: 'Switzerland', code: '41', min: 5, max: 15 },
    TW: { name: 'Taiwan', code: '886', min: 5, max: 15 },
    TJ: { name: 'Tajikistan', code: '992', min: 5, max: 15 },
    TZ: { name: 'Tanzania', code: '255', min: 5, max: 15 },
    TH: { name: 'Thailand', code: '66', min: 5, max: 15 },
    TG: { name: 'Togo', code: '228', min: 5, max: 15 },
    TK: { name: 'Tokelau', code: '690', min: 5, max: 15 },
    TO: { name: 'Tonga', code: '676', min: 5, max: 15 },
    TT: { name: 'Trinidad and Tobago', code: '1-868', min: 5, max: 15 },
    TN: { name: 'Tunisia', code: '216', min: 5, max: 15 },
    TR: { name: 'Turkey', code: '90', min: 5, max: 15 },
    TM: { name: 'Turkmenistan', code: '993', min: 5, max: 15 },
    TC: { name: 'Turks and Caicos Islands', code: '1-649', min: 5, max: 15 },
    TV: { name: 'Tuvalu', code: '688', min: 5, max: 15 },
    VI: { name: 'U.S. Virgin Islands', code: '1-340', min: 5, max: 15 },
    UG: { name: 'Uganda', code: '256', min: 5, max: 15 },
    UA: { name: 'Ukraine', code: '380', min: 5, max: 15 },
    AE: { name: 'United Arab Emirates', code: '971', min: 8, max: 9 },
    GB: { name: 'United Kingdom', code: '44', min: 5, max: 15 },
    US: { name: 'United States', code: '1', min: 5, max: 15 },
    UY: { name: 'Uruguay', code: '598', min: 5, max: 15 },
    UZ: { name: 'Uzbekistan', code: '998', min: 5, max: 15 },
    VU: { name: 'Vanuatu', code: '678', min: 5, max: 15 },
    VA: { name: 'Vatican', code: '379', min: 5, max: 15 },
    VE: { name: 'Venezuela', code: '58', min: 5, max: 15 },
    VN: { name: 'Vietnam', code: '84', min: 5, max: 15 },
    WF: { name: 'Wallis and Futuna', code: '681', min: 5, max: 15 },
    EH: { name: 'Western Sahara', code: '212', min: 5, max: 15 },
    YE: { name: 'Yemen', code: '967', min: 5, max: 15 },
    ZM: { name: 'Zambia', code: '260', min: 5, max: 15 },
    ZW: { name: 'Zimbabwe', code: '263', min: 5, max: 15 }
}
