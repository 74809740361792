import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const DropdownItem = ({ className, children, itemId, value, onClick, selected, onMouseOver }) => {
    const itemClassName = classNames(
        'ls-reset br-4 w-100p px-2 dropdown__item',
        {
            'bg-secondary-500 c-white dropdown__selected': selected,
            'c-neutral-900': !selected
        },
        className
    )

    return (
        <li
            onClick={onClick}
            key={itemId}
            value={value}
            className={itemClassName}
            style={{
                paddingTop: '6px',
                paddingBottom: '6px'
            }}
            onMouseOver={onMouseOver}
        >
            {children}
        </li>
    )
}

DropdownItem.propType = {
    className: PropTypes.string,
    children: PropTypes.node,
    itemId: PropTypes.string,
    value: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    onMouseOver: PropTypes.func
}

DropdownItem.defaultProps = {
    className: '',
    onClick: () => {},
    selected: false,
    onMouseOver: () => {}
}

export default DropdownItem
