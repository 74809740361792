import React from 'react'
import classNames from 'classnames'

const Overlay = ({ className, children, style, onOverlayClick = () => {} }) => {
    const isHq= window?.location?.href?.includes("hq") || window?.location?.href?.includes("signin")
    return (
        <div
            className={classNames('p-fixed l-0 r-0 b-0 t-0 flex flex-center flex-middle', className)}
            style={{
                backgroundColor: `${isHq ? '#BFBEBE':'rgba(0, 0, 0,0.2)'}`,
                ...style
            }}
            onClick={onOverlayClick}
        >
            {children}
        </div>
    )
}

export default Overlay
