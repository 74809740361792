import React, { useState, useEffect } from 'react'
import { Spacer } from '@cleartrip/bento'
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon.svg'
import langTranslate from 'locale'
const OfflineToast = ({ page }) => {
    const [isOffline, setIsOffline] = useState(false)

    function onlineCheck(e) {
        setIsOffline(e.type === 'offline')
    }

    useEffect(() => {
        window.addEventListener('offline', onlineCheck)
        window.addEventListener('online', onlineCheck)
        return () => {
            window.removeEventListener('offline', onlineCheck)
            window.removeEventListener('online', onlineCheck)
        }
    }, [])

    function renderOfflineToast() {
        return (
            <>
                <div className="py-2 px-2 h-8 flex flex-middle bg-error-600 c-white fs-2 br-4">
                    <InfoIcon className="c-white" height={16} width={16} />
                    <span className="fs-inherit c-inherit pl-2">
                        {langTranslate(`No internet connection, unable to`) + ' ' + langTranslate(`${page}`)}
                    </span>
                </div>
                <Spacer className="pt-8" />
            </>
        )
    }
    if (isOffline) return renderOfflineToast()
    return null
}

export default OfflineToast
