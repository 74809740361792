import React, { Children, useState, useEffect } from 'react'
import classNames from 'classnames'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import OutsideClickHandler from '../../lib/OutsideClickHandler'

import Overlay from 'lib/Overlay'

const Dropdown = ({ className, children, overlayClassName, toggle, toggleFn, overlay }) => {
    const [active, setActive] = useState(false)

    function toggleDropdown(toggleVal) {
        if (toggleVal === 'open') {
            setActive(true)
            toggleFn(true)
        } else if (toggleVal === 'close') {
            setActive(false)
            toggleFn(false)
        } else {
            setActive(!active)
            toggleFn(!active)
        }
    }
    function getConnectedChildren() {
        const connectedChildren = []
        const dropdownSelect = Children.toArray(children)[0]
        const dropdownMenu = Children.toArray(children)[1]
        const connectedDropdownSelect = React.cloneElement(dropdownSelect, {
            onClick: toggleDropdown,
            active: active
        })
        const connectedDropdownMenu = React.cloneElement(dropdownMenu, {
            active: active,
            toggleDropdown: toggleDropdown
        })
        connectedChildren.push(connectedDropdownSelect, connectedDropdownMenu)
        return connectedChildren
    }

    useEffect(() => {
        if (toggle) {
            setActive(!active)
        }
    }, [toggle])

    return (
        <>
            <OutsideClickHandler disabled={!active} onOutsideClick={() => setActive(false)}>
                <div className={classNames('p-relative', className)}>{getConnectedChildren()}</div>
            </OutsideClickHandler>

            {overlay && active && ReactDOM.createPortal(<Overlay className={overlayClassName} />, document.body)}
        </>
    )
}
Dropdown.propType = {
    className: PropTypes.string,
    children: PropTypes.node,
    overlayClassName: PropTypes.string,
    toggle: PropTypes.bool,
    toggleFn: PropTypes.func,
    overlay: PropTypes.bool
}

Dropdown.defaultProps = {
    className: '',
    children: '',
    overlayClassName: '',
    toggle: '',
    toggleFn: () => {},
    overlay: true
}

export default Dropdown
