import React, { useState, useEffect, useRef, useContext } from 'react'
import classNames from 'classnames'
import { Spacer, Field, Button, Label, Divider, Checkbox, Card, Icon, Row, Col, Collapse } from '@cleartrip/bento'
import Modal from 'lib/Modal'
import ModalHeader from './UserProfileModalHeader'
import Dropdown from 'components/Dropdown'
import {
    validateEmail,
    validateMobileNumber,
    validatePassword,
    onlyNumbersRegex,
    validateLastName,
    validateFirstName
} from 'utils/flights/itinerary'
import USER_PROFILE_PAGE_CONTANTS from './UserProfileConstants'
import langTranslate from 'locale'
import { userSignIn, userResetPassword, userSignUp, getUserByEmailId } from 'api/flights/results'
import OfflineToast from 'components/GlobalNav/UserProfile/offlineToast'
import { dataLayerGA } from 'utils/dataLayerHelper.js'
import { isEmpty } from 'utils/browserHelper'
import { DOMAIN } from 'constants/base'
import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'
import { MOBILE_COUNTRY_CODES, POPULAR_MOBILE_COUNTRY } from 'constants/mobileCountryCodes'
import { ResultsDataContext } from 'context/flights/results'
import { ItineraryDataContext } from 'context/itinerary'

import { sendClevertapUIActionEvent as ClevertapEvent } from 'utils/globalNav/logPageLoadEvents'
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon.svg'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg'

import { formSrpSearchQuery } from 'utils/flights/results'
import { resolveKeysInObjectsArrays } from 'utils/browserHelper'
import { CleartripLogo } from '@cleartrip/bento-icons'
const isRtl = process.env.CONTENT_DIR === 'RTL'
function ViewContainer({ isShowing, toggle, page, handleIsShowing, isHqPage = false }) {
    const countryCode = DOMAIN_COUNTRY_MAP[DOMAIN] || 'IN'

    const defaultCode = MOBILE_COUNTRY_CODES[countryCode]['code'] || ''
    const defaultMinLength = MOBILE_COUNTRY_CODES[countryCode]['min'] || ''
    const defaultMaxLength = MOBILE_COUNTRY_CODES[countryCode]['max'] || ''

    const [pageName, setPageName] = useState(page)
    const [showPasswordRules, setShowPasswordRules] = useState(false)
    const animationDefaultState = { isAnimate: false, direction: 'right', isFadeIn: false }
    const [animationParams, setAnimationParams] = useState(animationDefaultState)
    // const [isAnimate, setIsAnimate] = useState(false)
    const [showGSTIN, setShowGSTIN] = useState(false)
    const [rememberCheckedStatus, setRememberCheckedStatus] = useState(false)
    const [travelCheckedStatus, setTravelCheckedStatus] = useState(false)
    const [signInError, setSignInError] = useState(false)

    let [email, setEmail] = useState('')
    let [isEmailValid, setEmailValid] = useState(true)
    let [firstName, setFirstName] = useState('')
    let [isValidFirstName, setFirstNameValid] = useState(true)
    const [accountExists, setAccountExists] = useState(false)
    let [lastName, setLastName] = useState('')
    let [isValidLastName, setLastNameValid] = useState(true)
    const [gstin, setGstin] = useState('')
    let [isGSTValid, setGSTValid] = useState(true)
    const [companyName, setCompanyName] = useState('')
    let [isCompanyValid, setCompanyValid] = useState(true)
    let [password, setPassword] = useState('')
    let [confirmPassword, setConfirmPassword] = useState('')
    let [isPasswordValid, setPasswordValid] = useState(true)
    let [isConfirmPasswordValid, setConfirmPasswordValid] = useState(true)
    const [defaultCountryCode, setDefaultCountryCode] = useState(isRtl ? defaultCode + '+' : '+' + defaultCode)
    const [defaultCountryCodeMinLen, setDefaultCountryCodeMinLen] = useState(defaultMinLength)
    const [defaultCountryCodeMaxLen, setDefaultCountryCodeMaxLen] = useState(defaultMaxLength)
    let [isMobileValid, setMobileValid] = useState(true)
    let [passwordType, setPasswordType] = useState(true)
    let [confirmPasswordType, setConfirmPasswordType] = useState(true)
    const [toggleGender, setToggleGender] = useState(false)
    const [gender, setGender] = useState('')
    const [genderError, setGenderError] = useState(false)
    let [mobileNumber, setMobileNumber] = useState('')
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const [signUpFormError, setSignUpFormError] = useState(false)
    const [showEmailNotFound, setShowEmailNotFound] = useState(false)
    const [resultsData] = useContext(ResultsDataContext)

    const [itineraryData] = useContext(ItineraryDataContext)
    const errorRef = useRef(null)

    const gstinRegex = new RegExp('^((0[1-9])|([1-2][0-9])|(3[0-7]))[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{3}?$')
    const oneSpecialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    const lowerCaseRegex = /[a-z]/
    const uperCaseRegex = /[A-Z]/
    const numberRegex = /[0-9]/
    const animatedClass = classNames({
        'slide-in-right': animationParams.direction === 'right' && animationParams.isAnimate,
        'slide-in-left': animationParams.direction === 'left' && animationParams.isAnimate,
        'px-8': 'px-8'
    })
    const query = resolveKeysInObjectsArrays(['location', 'pathname'], window)
    const pathname = query.split('/')
    const pagePath = pathname[2]

    useEffect(() => {
        if (!isShowing) {
            setPageName(null)
            setShowPasswordRules(false)
            setShowGSTIN(false)
        } else setPageName(page)
    }, [page, isShowing])

    useEffect(() => {
        if (pageName === 'SIGNIN' || pageName === 'SIGNUP' || pageName === 'RESET') {
            setToDefault()
        }
    }, [pageName])

    const getSearchUrlParams = () => {
        const searchUrl = location.search || ''
        let params = searchUrl
        if (searchUrl.indexOf('?') > -1) {
            params = searchUrl.slice(1)
        }

        const urlParam = formSrpSearchQuery(params)
        return urlParam
    }

    let urlParam = ''

    if (pagePath === 'results') {
        urlParam = getSearchUrlParams()
    }

    function goBackAnimation(page) {
        setAnimationParams({
            isAnimate: true,
            direction: 'left'
        })
        setTimeout(() => {
            clickHandler(page, false)
        }, 200)
    }

    function clickHandler(page, isAnimate) {
        setAnimationParams({ isAnimate: isAnimate, direction: isRtl ? 'left' : 'right' })

        setPageName(page)
        setTimeout(() => {
            setAnimationParams(animationDefaultState)
        }, 300)
    }

    const executeScroll = () => errorRef.current.scrollIntoView()
    const resetPassword = async (page, isAnimate, email) => {
        const validateEmail = validateEmailHandler()
        if (!validateEmail) {
            setEmailValid(validateEmail)
        } else {
            const resetPwdResponse = await userResetPassword(email)

            const { status: resetPwdStatus } = resetPwdResponse || {}

            if (resetPwdStatus == 202) {
                setAnimationParams({ isAnimate: isAnimate, direction: 'right' })

                setPageName(page)
                setTimeout(() => {
                    setAnimationParams(animationDefaultState)
                }, 300)
            } else {
                setShowEmailNotFound(true)
                setEmailValid(false)
            }
        }
    }

    const setToDefault = () => {
        setMobileValid(true)
        setEmailValid(true)
        setPasswordValid(true)
        setConfirmPasswordValid(true)
        setShowEmailNotFound(false)
        setSignInError(false)
        setShowGSTIN(false)
        setEmail('')
        setFirstName('')
        setFirstNameValid(true)
        setLastName('')
        setLastNameValid(true)
        setGSTValid(true)
        setGstin('')
        setCompanyName('')
        setCompanyValid(true)
        setPassword('')
        setConfirmPassword('')
        setPasswordType(true)
        setConfirmPasswordType(true)
        setToggleGender(false)
        setGender('')
        setMobileNumber('')
        setToggleDropdown(false)
        setSignUpFormError(false)
        setGenderError(false)
        setAccountExists(false)
        //can add more default states if needed
    }

    const renderPage = () => {
        switch (pageName) {
            case USER_PROFILE_PAGE_CONTANTS.SIGNIN_PAGE:
                return SigninBlock
            case USER_PROFILE_PAGE_CONTANTS.RESET_PAGE:
                return ResetBlock
            case USER_PROFILE_PAGE_CONTANTS.SIGNUP_PAGE:
                return SignupBlock
            case USER_PROFILE_PAGE_CONTANTS.RESET_CONFIRMATION_PAGE:
                return ResetConfirmationBlock
            case USER_PROFILE_PAGE_CONTANTS.SIGNEDIN_USER_PAGE:
                return SignedInUserBlock
            case USER_PROFILE_PAGE_CONTANTS.SIGNUP_FORM_PAGE:
                return SignUpFormBlock
            default:
                return null
        }
    }

    const signUpContinue = async () => {
        if (email.length === 0) {
            setEmailValid(false)
        } else if (isEmailValid) {
            ClevertapEvent({
                actionName: 'continue_sign_up',
                actionType: 'Button',
                page: 'flights_sign_up',
                urlParam,
                resultsData,
                itineraryData,
                currentPage: pagePath
            })
            const response = await getUserByEmailId(email)
            const { status } = response
            if (status === 200) {
                setPasswordValid(true)
                clickHandler(USER_PROFILE_PAGE_CONTANTS.SIGNEDIN_USER_PAGE, true)
            } else {
                clickHandler(USER_PROFILE_PAGE_CONTANTS.SIGNUP_FORM_PAGE, true)
            }
        }
    }

    const handleLinkClick = url => {
        let dest = `${window.location.origin}/${url}`
        window.open(dest, '_blank')
    }
    const changeEmailHandler = e => {
        setEmailValid(true)
        setShowEmailNotFound(false)
        setEmail(e.target.value)
    }
    const validateEmailHandler = e => {
        const temp = validateEmail(email)
        setEmailValid(temp)
        return temp
    }
    const changeCompanyHandler = e => {
        let companyValid = true
        setCompanyName(e.currentTarget.value)
        companyValid = !isEmpty(companyName)
        setCompanyValid(companyValid)
    }
    const changeGstinHandler = e => {
        const gstin = e.currentTarget.value
        if (gstin.length <= 15) {
            setGstin(gstin.toUpperCase())
        }
        setGSTValid(true)
    }
    const changeFirstNameHandler = e => {
        setFirstNameValid(true)
        setFirstName(e.target.value)
    }
    const changeLastNameHandler = e => {
        setLastNameValid(true)
        setLastName(e.target.value)
    }
    const validateFirstNameHandler = e => {
        const temp = validateFirstName(firstName, 32)

        setFirstNameValid(temp)
        return temp
    }
    const validateLastNameHandler = e => {
        const temp = validateLastName(lastName, 1, 32)
        setLastNameValid(temp)
        return temp
    }
    const changePasswordHandler = e => {
        setPasswordValid(true)
        setPassword(e.target.value)
    }
    const changeConfirmPasswordHandler = e => {
        setConfirmPasswordValid(true)
        setConfirmPassword(e.target.value)
    }

    const validatePasswordHandler = e => {
        const temp = validatePassword(password)
        setPasswordValid(temp)
        return temp
    }
    const validateConfirmPasswordHandler = e => {
        const temp = confirmPassword === password ? true : false
        setConfirmPasswordValid(temp)
        return temp
    }

    const validateMobileHandler = e => {
        const isMobileValid = validateMobileNumber(mobileNumber, defaultCountryCodeMinLen, defaultCountryCodeMaxLen)
        setMobileValid(isMobileValid)
        return isMobileValid
    }
    const changeMobileHandler = e => {
        const mobile = e.target.value
        if (!isEmpty(mobile) && !onlyNumbersRegex.test(mobile)) {
            return
        }
        if (mobile.length <= defaultCountryCodeMaxLen) {
            setMobileValid(true)
            setMobileNumber(mobile)
        }
    }
    const countryCodeHandler = (code, min, max) => {
        if (isRtl) setDefaultCountryCode(code + '+')
        else setDefaultCountryCode('+' + code)
        setDefaultCountryCodeMinLen(min)
        setDefaultCountryCodeMaxLen(max)
        setToggleDropdown(toggleDropdown === code ? true : code)
    }
    const mobileCountryCodesItem = codeObj => {
        const { name, code, min, max } = codeObj
        return (
            <Dropdown.Item
                selected={'+' + code === defaultCountryCode}
                className="fs-3  hover:bg-secondary-500 c-pointer hover:c-white"
                onClick={() => countryCodeHandler(code, min, max)}
            >
                {name} (+{code})
            </Dropdown.Item>
        )
    }
    const populateMobileCountryCode = () => {
        const mobileCountryCodes = []
        for (let popularCountry of POPULAR_MOBILE_COUNTRY) {
            const codeObj = MOBILE_COUNTRY_CODES[popularCountry]
            mobileCountryCodes.push(mobileCountryCodesItem(codeObj))
        }
        for (let countryCode in MOBILE_COUNTRY_CODES) {
            const codeObj = MOBILE_COUNTRY_CODES[countryCode]
            mobileCountryCodes.push(mobileCountryCodesItem(codeObj))
        }
        return mobileCountryCodes
    }
    const selectGender = e => {
        const arabicGenderMapping = {
            [langTranslate('Male')]: 'Male',
            [langTranslate('Female')]: 'Female'
        }
        const selectedGender = arabicGenderMapping[e.currentTarget.innerText] || ''

        // onChangeHandler(traveller, 'gender', selectedGender)
        setGender(selectedGender)
        setToggleGender(toggleGender === selectedGender ? true : selectedGender)
        setGenderError(false)
    }
    const goToHome =()=>{
        return window.location.href= '/'
    }
    let spanClass = 'fs-2 c-secondary-500 c-pointer hover:td-underline p-absolute h-9 flex flex-middle r-2 fw-500 t-0'
    if (isRtl) {
        spanClass = 'fs-2 c-secondary-500 c-pointer hover:td-underline p-absolute h-9 flex flex-middle l-2 fw-500 t-0'
    }
    let checkboxClass = 'nl-1'
    if (isRtl) {
        checkboxClass = ''
    }
    let checkBoxTextClass = 'pl-1 c-neutral-700  fs-2 nl-1'
    if (isRtl) {
        checkBoxTextClass = 'pr-2 c-neutral-700  fs-2 '
    }

    let errorInfo = 'fs-inherit c-inherit pl-2'
    if (isRtl) {
        errorInfo = 'fs-inherit c-inherit pr-2'
    }

    const mobileAndCountryCodeItem = isRtl ? (
        <Row>
            <Col>
                <Field
                    type="text"
                    placeholder={langTranslate('Mobile number')}
                    onChange={changeMobileHandler}
                    onBlur={validateMobileHandler}
                    hasError={!isMobileValid}
                    value={mobileNumber}
                    style={{ direction: 'ltr' }}
                />
                {!isMobileValid && (
                    <div className="c-error-500 fs-2 mt-1">{langTranslate('Please enter a valid phone number')}</div>
                )}
            </Col>
            <Col span="7">
                <Dropdown overlay={false} toggle={toggleDropdown}>
                    <Dropdown.Select className="h-9 p-0 px-2 fs-3" width={80} label={defaultCountryCode} />
                    <Dropdown.Menu width={300} className="oy-auto" style={{ maxHeight: '198px' }}>
                        {populateMobileCountryCode()}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col span="6">
                <Dropdown overlay={false} toggle={toggleDropdown}>
                    <Dropdown.Select className="h-9 p-0 px-2 fs-3" width={80} label={defaultCountryCode} />
                    <Dropdown.Menu width={300} className="oy-auto" style={{ maxHeight: '198px' }}>
                        {populateMobileCountryCode()}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col>
                <Field
                    data-testid="Mobile"
                    type="text"
                    placeholder={langTranslate('Mobile number')}
                    onChange={changeMobileHandler}
                    onBlur={validateMobileHandler}
                    hasError={!isMobileValid}
                    value={mobileNumber}
                />
                {!isMobileValid && (
                    <div data-testid="invalidMobileMessage" className="c-error-500 fs-2 mt-1">
                        {langTranslate('Please enter a valid phone number')}
                    </div>
                )}
            </Col>
        </Row>
    )

    const signUpCreate = async () => {
        let signUpError = false
        if (isEmpty(password)) {
            setPasswordValid(false)
            signUpError = true
        } else if (
            !uperCaseRegex.test(password) ||
            !numberRegex.test(password) ||
            !lowerCaseRegex.test(password) ||
            !oneSpecialCharacterRegex.test(password) ||
            password.length < 8
        ) {
            setPasswordValid(false)
            signUpError = true
        }
        if (isEmpty(confirmPassword)) {
            setConfirmPasswordValid(false)
            signUpError = true
        }
        if (isEmpty(firstName)) {
            setFirstNameValid(false)
            signUpError = true
        }
        if (isEmpty(lastName)) {
            setLastNameValid(false)
            signUpError = true
        }
        if (!isConfirmPasswordValid) {
            signUpError = true
        }
        if (!isValidFirstName) {
            signUpError = true
        }
        if (!isValidLastName) {
            signUpError = true
        }
        if (isEmpty(gender)) {
            setGenderError(true)
            signUpError = true
        }
        if (!validateMobileHandler()) {
            setMobileValid(false)
            signUpError = true
        }
        if (showGSTIN) {
            if (isEmpty(companyName)) {
                setCompanyValid(false)
                signUpError = true
            }
            if (isEmpty(gstin)) {
                setGSTValid(false)
                signUpError = true
            } else if (!gstinRegex.test(gstin)) {
                setGSTValid(false)
                signUpError = true
            }
        }

        if (signUpError) {
            setSignUpFormError(true)
            setTimeout(() => {
                executeScroll()
            }, 200)
        } else {
            ClevertapEvent({
                actionName: 'create_account',
                actionType: 'Button',
                page: 'flights_sign_up',
                urlParam,
                resultsData,
                itineraryData,
                currentPage: pagePath
            })
            setSignUpFormError(false)
            let countryCode = defaultCountryCode.substring(1)
            let payload = {
                username: email,
                gstDetails: [
                    {
                        id: '',
                        gstHolderAddress: null,
                        gstHolderName: '',
                        gstHolderStateCode: null,
                        gstHolderStateName: null,
                        gstNumber: gstin
                    }
                ],
                companyDetails: [
                    {
                        companyName: companyName,
                        domain: window.location.domain,
                        status: '1'
                    }
                ],
                travellerDetails: [
                    {
                        isRegistered: true,
                        profileData: null,
                        contactInfo: {
                            phoneNumbers: [
                                {
                                    mobileNumber: mobileNumber,
                                    mobileCountryCode: countryCode,
                                    category: 'mobile'
                                }
                            ],
                            whatsapp: null,
                            addresses: [],
                            otherDetails: [],
                            emails: []
                        },
                        ffnPreferences: null,
                        personalDetails: {
                            anniversaryDate: null,
                            companyDesignation: null,
                            concatName: `${firstName}${lastName}${email}`,
                            countryOfResidence: null,
                            countryOfResidenceId: null,
                            countryPreference: null,
                            currency: null,
                            dateOfBirth: null,
                            department: null,
                            emergencyContactName: null,
                            emergencyContactNumber: null,
                            firstName: `${firstName}`,
                            gender: `${gender}`,
                            homeAirport: null,
                            homeAirportId: null,
                            language: null,
                            lastName: `${lastName}`,
                            middleName: null,
                            nickName: null,
                            primaryEmail: null,
                            title: ''
                        },
                        preferences: null,
                        docDetails: null
                    }
                ],
                password: password
            }

            const apiResponse = await userSignUp(payload)
            const { status, error } = apiResponse
            if (error && error.response && error.response.status === 403) {
                setAccountExists(true)
            } else if (status === 202) {
                const payload = {
                    username: email,
                    password: password
                }

                const apiResponse = await userSignIn(payload)
                setTimeout(() => {
                    handleIsShowing()
                }, 300)
                setTimeout(() => {
                    window.location.reload()
                }, 400)
            } else {
                setSignUpFormError(true)
            }
        }
    }

    const getUrlFromQueryParams = isPageChange => {
        const queryParams = new URLSearchParams(resolveKeysInObjectsArrays(['location', 'search'], window))
        let redirectionPath = decodeURIComponent(queryParams.get('service'))
        if (!redirectionPath || redirectionPath === 'null' || redirectionPath === 'undefined') {
            return
        }
        return isPageChange ? '?service=' + redirectionPath : redirectionPath
    }

    const onContinue = async () => {
        // Reset Error
        setSignInError('')
        const emailValidation = validateEmailHandler()
        setEmailValid(emailValidation)
        if (emailValidation && isEmailValid && email) {
            const isValidPassword = validatePasswordHandler()
            if (!isValidPassword) {
                return false
            }
            // User Login
            ClevertapEvent({
                actionName: 'sign_in_clicked',
                actionType: 'Button',
                page: 'flights_sign_in',
                urlParam,
                resultsData,
                itineraryData,
                currentPage: pagePath
            })
            const payload = {
                username: email,
                password: password
            }

            const apiResponse = await userSignIn(payload)
            console.log('Sign in Response', apiResponse)
            const { data: { status: dataStatus } = {}, status } = apiResponse
            if (status !== 200 || dataStatus === 'INTERNAL_SERVER_ERROR') {
                const { error: { response: { data: { message = '' } = {} } = {} } = {} } = apiResponse
                if (message.includes(langTranslate('username and password do not match'))) {
                    setSignInError(langTranslate('Entered email and password does not match.'))

                    setPasswordValid(false)
                } else if (message.includes('no user found')) {
                    setSignInError(langTranslate('Entered email is not found'))
                    setEmailValid(false)
                    setPasswordValid(false)
                } else {
                    setSignInError(langTranslate('Something went wrong. Please try again.'))
                }
                return false
            } else if (status == 200) {
                

                // TODO-AB : OTP case
                if(isHqPage){
                    const redirectionPath = getUrlFromQueryParams()
                    window.location.href = redirectionPath
                }else{
                    setTimeout(() => {
                        window.location.reload()
                    }, 100)
                }
               
            }
        } else {
            const isValidPassword = validatePasswordHandler()
        }
    }

    const SigninBlock = (
        <div
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onContinue()
                }
            }}
            className={`${animatedClass} ${isHqPage && 'pt-10'}`}
        >
            {isHqPage &&  <CleartripLogo className="ct-logo" color="#214497" height={30} width={146} onClick={goToHome} style={{cursor:'pointer'}}/>}
            <ModalHeader title={langTranslate('Sign in to Cleartrip')} toggle={toggle} isHqPage = {isHqPage}/>
            <div className="oy-auto px-8 nmx-8" style={{ maxHeight: '368px' }}>
                <OfflineToast page="sign in" />
                {signInError && (
                    <>
                        <div className="py-2 px-2 h-8 flex flex-middle bg-error-600 c-white fs-2 br-4 ">
                            <InfoIcon className="c-white" height={16} width={16} />
                            <span data-testid="signinError" className={errorInfo}>
                                {langTranslate(signInError)}
                            </span>
                        </div>
                        <Spacer className="pt-8" />
                    </>
                )}

                <Field
                    placeholder={langTranslate('Email address')}
                    onBlur={validateEmailHandler}
                    onChange={changeEmailHandler}
                    hasError={!isEmailValid}
                    value={email}
                    data-testid="email"
                />
                {!isEmailValid && (
                    <div data-testid="invalidEmailMessage" className="c-error-500 fs-2 mt-1">
                        {langTranslate('Please enter a valid email address')}
                    </div>
                )}
                <Spacer className="pt-4" />
                <div className="p-relative">
                    <Field
                        placeholder={langTranslate('Password')}
                        type="password"
                        onBlur={validatePasswordHandler}
                        onChange={changePasswordHandler}
                        hasError={!isPasswordValid}
                        value={password}
                        data-testid="password"
                    />
                    {!isPasswordValid &&
                        (password.length === 0 ? (
                            <div data-testid="invalidPassword" className="c-error-500 fs-2 mt-1">
                                {langTranslate('Password cannot be left blank')}
                            </div>
                        ) : (
                            <div className="c-error-500 fs-2 mt-1">{langTranslate('Incorrect password')}</div>
                        ))}
                    <span
                        data-testid="forgot"
                        onClick={() => {
                            ClevertapEvent({
                                actionName: 'forgot_pwd',
                                actionType: 'Button',
                                page: 'flights_sign_in',
                                urlParam,
                                resultsData,
                                itineraryData,
                                currentPage: pagePath
                            })
                            clickHandler(USER_PROFILE_PAGE_CONTANTS.RESET_PAGE, true)
                        }}
                        className={spanClass}
                    >
                        {langTranslate('Forgot?')}
                    </span>
                </div>

                <Spacer className="pt-6" />
                <div>
                    <Checkbox
                        className={checkboxClass}
                        checked={rememberCheckedStatus}
                        onClick={() => setRememberCheckedStatus(!rememberCheckedStatus)}
                    >
                        <span className={checkBoxTextClass}>{langTranslate('Remember me on this browser')}</span>
                    </Checkbox>
                </div>

                <Spacer className="pt-3" />
                <div style={{ direction: isRtl ? 'rtl' : '' }}>
                    <span className="c-neutral-700 fs-2">
                        {langTranslate("By signing in, you agree to Cleartrip's ")}
                    </span>
                    <span
                        className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                        onClick={() => handleLinkClick('terms-of-service')}
                    >
                        {langTranslate('Terms of Use ')}
                    </span>
                    <span className="c-neutral-700 fs-2">{langTranslate('and ')}</span>
                    <span
                        style={isRtl ? { marginRight: '-2px' } : { marginLeft: '-2px' }}
                        className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                        onClick={() => handleLinkClick('privacy-policy')}
                    >
                        {langTranslate('Privacy Policy')}
                    </span>
                    <span className="c-neutral-700 fs-2">{langTranslate('.')}</span>
                </div>

                <Spacer className="pt-3" />
                <Button full type="secondary" size="md" onClick={() => onContinue()}>
                    <span className="fs-3 fw-550"> {langTranslate('Sign in')}</span>
                </Button>
                <Spacer className="pt-5" />
                {/* <Divider text="or" />
                <Spacer className="pt-5" />
                <div className="h-9 px-4 ba bc-neutral-100 br-4 c-neutral-700 fs-2 bw-1 ta-center hover:bc-secondary-500 t-all flex flex-middle flex-center c-pointer">
                    <FbLogo height={18} width={18} />
                    <span onClick={() => facebookLogin()} className="fs-2 fw-500 c-neutral-900 pl-4">
                        {langTranslate('Sign in with facebook')}
                    </span>
                </div> */}
                <Spacer className="pt-6" />
                <Divider />
                <Spacer className="pt-5" />
                <div className="flex flex-start flex-bottom flex-center">
                    <span className="c-neutral-700 fs-2">{langTranslate('New to Cleartrip?')}</span>
                    <span
                        className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                        onClick={() => {
                            ClevertapEvent({
                                actionName: 'sign_up_clicked',
                                actionType: 'Button',
                                page: 'flights_sign_in',
                                urlParam,
                                resultsData,
                                itineraryData,
                                currentPage: pagePath
                            })
                            dataLayerGA({ registered_gender: gender, method: 'email' }, 'sign_up')
                            setPageName(USER_PROFILE_PAGE_CONTANTS.SIGNUP_PAGE)
                        }}
                    >
                        &nbsp;{langTranslate('Sign up')}
                    </span>
                </div>
                <Spacer className="pt-5" />
            </div>
        </div>
    )

    const SignupBlock = (
        <div
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    signUpContinue()
                }
            }}
            className={`${animatedClass} ${isHqPage && 'pt-10'}`}
        >
            {isHqPage && <CleartripLogo className="ct-logo" color="#214497" height={30} width={146} onClick={goToHome} style={{cursor:'pointer'}}/>}
            <ModalHeader title={langTranslate('Create a Cleartrip account')} toggle={toggle} isHqPage={isHqPage}/>
            <div className="oy-auto px-8 nmx-8" style={{ maxHeight: '368px' }}>
                <OfflineToast page="sign up" />
                <Field
                    placeholder={langTranslate('Enter email address')}
                    onBlur={validateEmailHandler}
                    onChange={changeEmailHandler}
                    hasError={!isEmailValid}
                    value={email}
                    data-testid={'signUpEmail'}
                />
                {!isEmailValid && (
                    <div data-testid="invalidEmail" className="c-error-500 fs-2 mt-1">
                        {langTranslate('Please enter a valid email address')}
                    </div>
                )}
                <Spacer className="pt-6" />
                <div>
                    <Checkbox
                        className={checkboxClass}
                        checked={travelCheckedStatus}
                        onClick={() => setTravelCheckedStatus(!travelCheckedStatus)}
                    >
                        <span className={checkBoxTextClass}>
                            {langTranslate('Send me travel offers, deals, and news by email and message')}
                        </span>
                    </Checkbox>
                </div>
                <Spacer className="pt-3" />
                <div style={{ direction: isRtl ? 'rtl' : '' }}>
                    <span className="c-neutral-700 fs-2">
                        {langTranslate("By continuing, you agree to Cleartrip's ")}
                    </span>
                    <span
                        className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                        onClick={() => handleLinkClick('terms-of-service')}
                    >
                        {langTranslate('Terms of Use ')}
                    </span>
                    <span className="c-neutral-700 fs-2">{langTranslate('and ')}</span>
                    <span
                        style={isRtl ? { marginRight: '-2px' } : { marginLeft: '-2px' }}
                        className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                        onClick={() => handleLinkClick('privacy-policy')}
                    >
                        {langTranslate('Privacy Policy')}
                    </span>
                    <span className="c-neutral-700 fs-2">{langTranslate('.')}</span>
                </div>

                <Spacer className="pt-3" />
                <Button full type="secondary" size="md" onClick={() => signUpContinue()}>
                    <span className="fs-3 fw-550">{langTranslate('Continue')}</span>
                </Button>
                <Spacer className="pt-5" />
                {/* <Divider text="or" />
                <Spacer className="pt-5" />
                <div className="h-9 px-4 ba bc-neutral-100 br-4 c-neutral-700 fs-2 bw-1 ta-center hover:bc-secondary-500 t-all flex flex-middle flex-center c-pointer">
                    <FbLogo height={18} width={18} />
                    <span className="fs-2 fw-500 c-neutral-900 pl-4">{langTranslate('Sign up with facebook')}</span>
                </div> */}
                <Spacer className="pt-8" />
                <div className="flex flex-start flex-bottom flex-center">
                    <span className="c-neutral-700 fs-2">{langTranslate('Already have a Cleartrip account?')}</span>
                    <span
                        className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                        onClick={() => setPageName(USER_PROFILE_PAGE_CONTANTS.SIGNIN_PAGE)}
                    >
                        &nbsp;{langTranslate('Sign in')}
                    </span>
                </div>
                <Spacer className="pt-8" />
                <Divider />
                <Spacer className="pt-5" />
                <Spacer className="pt-5" />
            </div>
        </div>
    )

    const SignUpFormBlock = (
        <>
        <div className='pt-4'>
            <div style={{paddingLeft: '30px'}}>
        {isHqPage &&<CleartripLogo className="ct-logo" color="#214497" height={30} width={146} onClick={goToHome} style={{cursor:'pointer'}}/>}
        </div>
            <div className="bb bc-neutral-100 px-8">
                <ModalHeader title={langTranslate('Create a Cleartrip account')} toggle={toggle} isHqPage={isHqPage}/>
            </div>
            <div
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        signUpCreate()
                    }
                }}
                className={animatedClass}
            >
                <div
                    className="oy-auto nmx-8 px-8 pt-4"
                    style={{
                        maxHeight: `${isHqPage ? '60vh':'340px'}`
                    }}
                >
                    {signUpFormError && (
                        <>
                            <div
                                ref={errorRef}
                                className="py-2 px-2 h-8 flex flex-middle bg-error-600 c-white fs-2 br-4"
                            >
                                <InfoIcon className="c-white" height={16} width={16} />
                                <span data-testid="createAccountError" className={errorInfo}>
                                    {langTranslate('Unable to create account, please resolve the errors')}
                                </span>
                            </div>
                            <Spacer className="pt-8" />
                        </>
                    )}
                    {accountExists && (
                        <>
                            <div className="py-2 px-2 h-8 flex flex-middle bg-error-600 c-white fs-2 br-4">
                                <InfoIcon className="c-white" height={16} width={16} />
                                <span className={errorInfo}>
                                    {langTranslate('User already exists for the given email')}
                                </span>
                            </div>
                            <Spacer className="pt-8" />
                        </>
                    )}
                    <OfflineToast page="sign up" />

                    <div className="fs-3 c-neutral-700" style={{ direction: isRtl ? 'rtl' : '' }}>
                        {langTranslate('For')} <span className="fw-600 fs-inherit c-inherit">{`${email}`}</span>
                    </div>
                    <Spacer className="pt-6" />
                    <Label className="fs-3 c-neutral-700">{langTranslate('Set a password')}</Label>
                    <Spacer className="pt-2" />
                    <div className="p-relative">
                        <Field
                            placeholder={langTranslate('Password')}
                            type={passwordType ? 'password' : 'text'}
                            onBlur={validatePasswordHandler}
                            onChange={changePasswordHandler}
                            hasError={!isPasswordValid}
                            value={password}
                            onClick={() => setShowPasswordRules(true)}
                            data-testid="passwordForm"
                        />
                        {!isPasswordValid && password.length === 0 && (
                            <div className="c-error-500 fs-2 mt-1">{langTranslate('This field cannot be empty')}</div>
                        )}
                        <span className={spanClass} onClick={() => setPasswordType(!passwordType)}>
                            {passwordType ? langTranslate('Show') : langTranslate('Hide')}
                        </span>
                    </div>
                    <Collapse pose={showPasswordRules ? 'open' : 'collapsed'}>
                        <Spacer className="pt-2" />
                        <Card className="px-3 py-3 bg-neutral-0 br-4">
                            <Row>
                                <Col span={12}>
                                    <div className="flex flex-middle">
                                        <div
                                            style={{ width: '14px', height: '14px' }}
                                            className="flex flex-middle flex-center"
                                        >
                                            <Icon
                                                data-testid="lowerCase"
                                                icon={lowerCaseRegex.test(password) ? 'tick' : 'circle-outline'}
                                                className={
                                                    lowerCaseRegex.test(password) ? 'c-success-500' : 'c-neutral-600'
                                                }
                                                height={lowerCaseRegex.test(password) ? 14 : 8}
                                                width={lowerCaseRegex.test(password) ? 14 : 8}
                                            />
                                        </div>
                                        <Spacer className="pl-1" />
                                        <span className="c-neutral-700 fs-2 ws-nowrap">
                                            {langTranslate('One lowercase letter')}
                                        </span>
                                    </div>
                                    <Spacer className="pt-2" />
                                    <div className="flex flex-middle">
                                        <div
                                            style={{ width: '14px', height: '14px' }}
                                            className="flex flex-middle flex-center"
                                        >
                                            <Icon
                                                data-testid="uperCase"
                                                icon={uperCaseRegex.test(password) ? 'tick' : 'circle-outline'}
                                                className={
                                                    uperCaseRegex.test(password) ? 'c-success-500' : 'c-neutral-600'
                                                }
                                                height={uperCaseRegex.test(password) ? 14 : 8}
                                                width={uperCaseRegex.test(password) ? 14 : 8}
                                            />
                                        </div>
                                        <Spacer className="pl-1" />
                                        <span className="c-neutral-700 fs-2 ws-nowrap">
                                            {langTranslate('One uppercase letter')}
                                        </span>
                                    </div>
                                    <Spacer className="pt-2" />
                                    <div className="flex flex-middle">
                                        <div
                                            style={{ width: '14px', height: '14px' }}
                                            className="flex flex-middle flex-center"
                                        >
                                            <Icon
                                                data-testid="oneNumber"
                                                icon={numberRegex.test(password) ? 'tick' : 'circle-outline'}
                                                className={
                                                    numberRegex.test(password) ? 'c-success-500' : 'c-neutral-600'
                                                }
                                                height={numberRegex.test(password) ? 14 : 8}
                                                width={numberRegex.test(password) ? 14 : 8}
                                            />
                                        </div>
                                        <Spacer className="pl-1" />
                                        <span className="c-neutral-700 fs-2 ws-nowrap">
                                            {langTranslate('One number')}
                                        </span>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="flex flex-middle">
                                        <div
                                            style={{ width: '14px', height: '14px' }}
                                            className="flex flex-middle flex-center"
                                        >
                                            <Icon
                                                data-testid="specialCharacter"
                                                icon={
                                                    oneSpecialCharacterRegex.test(password) ? 'tick' : 'circle-outline'
                                                }
                                                className={
                                                    oneSpecialCharacterRegex.test(password)
                                                        ? 'c-success-500'
                                                        : 'c-neutral-600'
                                                }
                                                height={oneSpecialCharacterRegex.test(password) ? 14 : 8}
                                                width={oneSpecialCharacterRegex.test(password) ? 14 : 8}
                                            />
                                        </div>
                                        <Spacer className="pl-1" />
                                        <span className="c-neutral-700 fs-2 ws-nowrap">
                                            {langTranslate('One special character')}
                                        </span>
                                    </div>
                                    <Spacer className="pt-2" />
                                    <div className="flex flex-middle">
                                        <div
                                            style={{ width: '14px', height: '14px' }}
                                            className="flex flex-middle flex-center"
                                        >
                                            <Icon
                                                data-testid="min8"
                                                icon={password.length >= 8 ? 'tick' : 'circle-outline'}
                                                className={password.length >= 8 ? 'c-success-500' : 'c-neutral-600'}
                                                height={password.length >= 8 ? 14 : 8}
                                                width={password.length >= 8 ? 14 : 8}
                                            />
                                        </div>
                                        <Spacer className="pl-1" />
                                        <span className="c-neutral-700 fs-2 ws-nowrap">
                                            {langTranslate('8 characters minimum')}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Collapse>
                    <Spacer className="pt-4" />
                    <div className="p-relative">
                        <Field
                            placeholder={langTranslate('Confirm password')}
                            type={confirmPasswordType ? 'password' : 'text'}
                            onBlur={validateConfirmPasswordHandler}
                            onChange={changeConfirmPasswordHandler}
                            hasError={!isConfirmPasswordValid}
                            value={confirmPassword}
                            data-testid={'passwordForm1'}
                        />
                        <span className={spanClass} onClick={() => setConfirmPasswordType(!confirmPasswordType)}>
                            {confirmPasswordType ? langTranslate('Show') : langTranslate('Hide')}
                        </span>
                    </div>
                    {!isConfirmPasswordValid && confirmPassword.length === 0 && (
                        <div className="c-error-500 fs-2 mt-1">{langTranslate('This field cannot be empty')}</div>
                    )}

                    {!isConfirmPasswordValid && confirmPassword.length > 0 && (
                        <div className="c-error-500 fs-2 mt-1">
                            {langTranslate('Please make sure your passwords match')}
                        </div>
                    )}

                    <Spacer className="pt-8" />
                    <Label className="fs-3 c-neutral-700">{langTranslate('Your name')}</Label>
                    <Spacer className="pt-2" />
                    <Field
                        placeholder={langTranslate('First name')}
                        onChange={changeFirstNameHandler}
                        hasError={!isValidFirstName}
                        value={firstName}
                        onBlur={validateFirstNameHandler}
                        data-testid="firstName"
                    />

                    {!isValidFirstName && firstName.length === 0 && (
                        <div data-testid="firstNameFieldEmpty" className="c-error-500 fs-2 mt-1">
                            {langTranslate('This field cannot be empty')}
                        </div>
                    )}

                    {!isValidFirstName && firstName.length > 0 && (
                        <div data-testid="firstNameFieldInvalid" className="c-error-500 fs-2 mt-1">
                            {langTranslate('Please enter a valid first name')}
                        </div>
                    )}

                    <Spacer className="pt-4" />
                    <div className="flex">
                        <div className="p-relative flex-1">
                            <Field
                                onChange={changeLastNameHandler}
                                hasError={!isValidLastName}
                                value={lastName}
                                onBlur={validateLastNameHandler}
                                placeholder={langTranslate('Last name')}
                                data-testid="lastName"
                            />
                            {!isValidLastName && lastName.length === 0 && (
                                <div data-testid="lastNameFieldEmpty" className="c-error-500 fs-2 mt-1">
                                    {langTranslate('This field cannot be empty')}
                                </div>
                            )}

                            {!isValidLastName && lastName.length > 0 && (
                                <div data-testid="lastNameFieldInvalid" className="c-error-500 fs-2 mt-1">
                                    {langTranslate('Please enter a valid last name')}
                                </div>
                            )}
                        </div>
                        <Spacer className="pr-2" />
                        <div className="p-relative">
                            <Dropdown overlay={false} toggle={toggleGender}>
                                <Dropdown.Select
                                    position={isRtl ? 'right' : 'left'}
                                    className="fs-3 h-9"
                                    width={80}
                                    placeHolder={langTranslate('Gender')}
                                    label={langTranslate(gender)}
                                    hasError={genderError}
                                />
                                <Dropdown.Menu width={112}>
                                    <Dropdown.Item
                                        className=" fs-3  hover:bg-secondary-500 c-pointer hover:c-white"
                                        onClick={e => selectGender(e)}
                                    >
                                        {langTranslate('Male')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="fs-3  hover:bg-secondary-500 c-pointer hover:c-white"
                                        onClick={e => selectGender(e)}
                                    >
                                        {langTranslate('Female')}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {genderError && (
                                <div className="c-error-500 fs-2 mt-1">{langTranslate('Please choose gender')}</div>
                            )}
                        </div>
                    </div>
                    <Spacer className="pt-8" />
                    <Label className="fs-3 c-neutral-700">{langTranslate('Mobile number')}</Label>
                    <Spacer className="pt-2" />
                    {mobileAndCountryCodeItem}
                    <Spacer className="pt-8" />
                    <div>
                        <Checkbox
                            data-testid="checkboxGst"
                            wrapperClassName="nml-1"
                            onClick={() => setShowGSTIN(!showGSTIN)}
                        >
                            <span className={isRtl ? checkBoxTextClass : 'pl-2 c-neutral-700  fs-2 nl-1 nmb-2'}>
                                {langTranslate('Link my GSTIN. Unlock exclusive benefits on all bookings.')}
                                <span
                                    className="pl-1 c-secondary-500 fs-inherit hover:td-underline c-pointer"
                                    onClick={() => handleLinkClick('cleartripforwork')}
                                >
                                    {langTranslate('Know more')}
                                </span>
                            </span>
                        </Checkbox>
                        <Spacer className="pt-2" />
                    </div>

                    <Spacer className="pt-3" />
                    <Collapse pose={showGSTIN ? 'open' : 'collapsed'}>
                        <Label className="fs-3 c-neutral-700">{langTranslate('GSTIN and company name')}</Label>
                        <Spacer className="pt-2" />
                        <div className="flex">
                            <Field
                                data-testid="gst"
                                placeholder={langTranslate('GSTIN number')}
                                value={gstin}
                                onChange={e => changeGstinHandler(e)}
                                hasError={!isGSTValid}
                            />

                            <Spacer className="pr-2" />
                            <Field
                                data-testid="company"
                                placeholder={langTranslate('Company name')}
                                value={companyName}
                                onChange={e => changeCompanyHandler(e)}
                                hasError={!isCompanyValid}
                            />
                        </div>
                        {!isGSTValid && (
                            <div className="c-error-500 fs-2 mt-1">{langTranslate('Please enter a valid GSTIN')}</div>
                        )}

                        {!isCompanyValid && (
                            <div className="c-error-500 fs-2 mt-1">
                                {langTranslate('Please enter a valid company name')}
                            </div>
                        )}
                        <Spacer className="pt-8" />
                    </Collapse>
                    <div>
                        <Checkbox
                            wrapperClassName="nml-1"
                            checked={travelCheckedStatus}
                            onClick={() => setTravelCheckedStatus(!travelCheckedStatus)}
                        >
                            <span className={isRtl ? checkBoxTextClass : 'pl-2 c-neutral-700  fs-2 nl-1'}>
                                {langTranslate('Send me travel offers, deals, and news by email and message')}
                            </span>
                        </Checkbox>
                    </div>
                    <Spacer className="pt-8" />
                    <Divider />
                    <Spacer className="pt-5" />
                    <div className="flex flex-start flex-bottom flex-center" style={{ direction: isRtl ? 'rtl' : '' }}>
                        <span className="c-neutral-700 fs-2">{langTranslate('Read Cleartrip’s')}</span>
                        <span
                            className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                            onClick={() => handleLinkClick('privacy-policy')}
                        >
                            &nbsp;{langTranslate('privacy policy')}
                        </span>
                        <span className="c-neutral-700 fs-2">&nbsp;&</span>
                        <span
                            className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                            onClick={() => handleLinkClick('terms-of-service')}
                        >
                            &nbsp;{langTranslate('terms of use')}
                        </span>
                    </div>
                    <Spacer className="pt-5" />
                </div>
                <div
                    className="nmx-8 elevation-3 ox-hidden flex flex-middle flex-between px-8 "
                    style={{
                        height: '68px',
                        boxShadow: '0 0 10px 1px rgba(0,0,0,0.1)'
                    }}
                >
                    <Button size="md" type="secondary" onClick={() => signUpCreate()} full>
                        <span className="fs-3 fw-550"> {langTranslate('Create account')}</span>
                    </Button>
                </div>
            </div>
            </div>
        </>
    )

    const ResetBlock = (
        <div
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    ClevertapEvent({
                        actionName: 'get_pwd',
                        actionType: 'Button',
                        page: 'flights_reset_pwd',
                        urlParam,
                        resultsData,
                        itineraryData,
                        currentPage: pagePath
                    })
                    resetPassword(USER_PROFILE_PAGE_CONTANTS.RESET_CONFIRMATION_PAGE, true, email)
                }
            }}
            className={`${animatedClass} ${isHqPage && 'pt-10'}`}
        >
            {isHqPage && <CleartripLogo className="ct-logo" color="#214497" height={30} width={146} onClick={goToHome} style={{cursor:'pointer'}}/>}
            <ModalHeader title={langTranslate('Reset your password')} toggle={toggle} isHqPage={isHqPage}/>

            <div style={{ minHeight:  `${isHqPage ? '230px': '300px'}` }}>
                <OfflineToast page="proceed" />
                <div
                    data-testid="resetSignIn"
                    onClick={() => goBackAnimation(USER_PROFILE_PAGE_CONTANTS.SIGNIN_PAGE)}
                    className="c-pointer flex flex-middle c-secondary-500 fs-3 fw-500"
                >
                    {!isRtl ? (
                        <ArrowLeft height={18} className="c-inherit" />
                    ) : (
                        <ArrowRight height={18} className="c-inherit" />
                    )}
                    &nbsp;<span className="fs-2"> {langTranslate('Sign in')}</span>
                </div>
                <Spacer className="pt-6" />
                <p className="fs-3 c-neutral-700">
                    {langTranslate('Confirm the email address you used to register or book on Cleartrip')}
                </p>
                <Spacer className="pt-3" />

                {showEmailNotFound && (
                    <>
                        <div className="py-2 px-2 h-8 flex flex-middle bg-error-600 c-white fs-2 br-4">
                            <InfoIcon className="c-white" height={16} width={16} />
                            <span className={errorInfo}>
                                {langTranslate('Email address is not registered with Cleartrip')}
                            </span>
                        </div>
                        <Spacer className="pt-8" />
                    </>
                )}

                <div className="p-relative">
                    <Field
                        data-testid="emailReset"
                        placeholder={langTranslate('Email address')}
                        onBlur={validateEmailHandler}
                        onChange={changeEmailHandler}
                        hasError={!isEmailValid}
                        value={email}
                    />
                    {/* {showEmailNotFound && (
                        <div className="c-error-500 fs-2 mt-1">
                            {langTranslate('Email address not registered with Cleartrip')}
                        </div>
                    )} */}
                    {!isEmailValid &&
                        (email === '' ? (
                            <div data-testid="invalidEmailReset" className="c-error-500 fs-2 mt-1">
                                {langTranslate('This field cannot be empty')}
                            </div>
                        ) : (
                            <div className="c-error-500 fs-2 mt-1">{langTranslate('Invalid email address')}</div>
                        ))}
                </div>
            </div>

            <Button
                data-testid="resetContinue"
                size="md"
                type="secondary"
                onClick={() => {
                    ClevertapEvent({
                        actionName: 'get_pwd',
                        actionType: 'Button',
                        page: 'flights_reset_pwd',
                        urlParam,
                        resultsData,
                        itineraryData,
                        currentPage: pagePath
                    })
                    resetPassword(USER_PROFILE_PAGE_CONTANTS.RESET_CONFIRMATION_PAGE, true, email)
                }}
                full
            >
                <span className="fs-3 fw-550">{langTranslate('Get password reset link')}</span>
            </Button>
        </div>
    )

    const ResetConfirmationBlock = (
        <div className={`${animatedClass} ${isHqPage && 'pt-10'}`}>
            {isHqPage &&<CleartripLogo className="ct-logo" color="#214497" height={30} width={146} onClick={goToHome} style={{cursor:'pointer'}}/>}
            <ModalHeader title={langTranslate('Check your email')} toggle={toggle} isHqPage={isHqPage}/>
            <div style={{ minHeight: `${isHqPage ?'180px':'248px'}` }}>
                <OfflineToast page="proceed" />
                <p className="fs-3 c-neutral-700">
                    {langTranslate('We’ve sent a password reset link to')}
                    <br />
                    <b>{`${email}`}</b>
                </p>
                <Spacer className="pt-5" />
                <Divider />
                <Spacer className="pt-5" />
                <p data-testid="confText" className="fs-2 c-neutral-700">
                    {langTranslate('Can’t find the email? Check your spam/junk folder or try again.')}
                </p>
            </div>

            <Button
                data-testid="resend"
                size="md"
                type="secondary"
                full
                outline
                onClick={() => goBackAnimation(USER_PROFILE_PAGE_CONTANTS.RESET_PAGE)}
            >
                <span className="fs-3 fw-550"> {langTranslate('Resend link')}</span>
            </Button>
            <Spacer className="pt-4" />
            <Button
                size="md"
                type="secondary"
                full
                onClick={() => goBackAnimation(USER_PROFILE_PAGE_CONTANTS.SIGNIN_PAGE)}
            >
                <span className="fs-3 fw-550">{langTranslate('Go to Sign in')}</span>
            </Button>
        </div>
    )

    const SignedInUserBlock = (
        <div
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onContinue()
                }
            }}
            className={`${animatedClass} ${isHqPage && 'pt-10'}`}
        >
            {isHqPage && <CleartripLogo className="ct-logo" color="#214497" height={30} width={146} onClick={goToHome} style={{cursor:'pointer'}}/>}
            <ModalHeader title={langTranslate('Sign in with password')} toggle={toggle} isHqPage={isHqPage}/>
            <div style={{ minHeight: '250px' }}>
                <OfflineToast page="sign in" />
                <div
                    // onClick={() => clickHandler(USER_PROFILE_PAGE_CONTANTS.SIGNUP_PAGE, true)}
                    onClick={() => goBackAnimation(USER_PROFILE_PAGE_CONTANTS.SIGNUP_PAGE)}
                    className="c-pointer flex flex-middle c-secondary-500 fs-3 fw-500"
                >
                    {!isRtl ? (
                        <ArrowLeft height={18} className="c-inherit" />
                    ) : (
                        <ArrowRight height={18} className="c-inherit" />
                    )}
                    &nbsp; <span className="fs-2">{langTranslate('Sign up')}</span>
                </div>
                <Spacer className="pt-6" />
                <p className="fs-3 c-neutral-700">
                    {langTranslate('For account')} <b>{`${email}`}</b>
                </p>
                <Spacer className="pt-3" />
                <div className="p-relative">
                    <Field
                        placeholder={langTranslate('Password')}
                        type="password"
                        onBlur={validatePasswordHandler}
                        onChange={changePasswordHandler}
                        hasError={!isPasswordValid}
                        value={password}
                    />
                    <span
                        onClick={() => clickHandler(USER_PROFILE_PAGE_CONTANTS.RESET_PAGE, true)}
                        className={spanClass}
                    >
                        {langTranslate('Forgot?')}
                    </span>
                    <Spacer className="pt-1" />
                    {!isPasswordValid &&
                        (password.length !== 0 ? (
                            <span className="c-error-500 fs-2 p-absolute">{langTranslate('Incorrect password')}</span>
                        ) : (
                            <span data-testid="incorrectPassword" className="c-error-500 fs-2 p-absolute">
                                {langTranslate('Password cannot be left blank')}
                            </span>
                        ))}
                </div>

                <Spacer className="pt-6" />
                <div>
                    <Checkbox
                        data-testid="remember"
                        className={checkboxClass}
                        checked={rememberCheckedStatus}
                        onClick={() => setRememberCheckedStatus(!rememberCheckedStatus)}
                    >
                        <span className={checkBoxTextClass}>{langTranslate('Remember me on this browser')}</span>
                    </Checkbox>
                </div>
            </div>
            <div style={{ direction: isRtl ? 'rtl' : '' }}>
                <span className="c-neutral-700 fs-2">{langTranslate("By signing in, you agree to Cleartrip's ")}</span>
                <span
                    className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                    onClick={() => handleLinkClick('terms-of-service')}
                >
                    {langTranslate('Terms of Use ')}
                </span>
                <span className="c-neutral-700 fs-2">{langTranslate('and ')}</span>
                <span
                    style={isRtl ? { marginRight: '-2px' } : { marginLeft: '-2px' }}
                    className="c-secondary-500 fw-500 fs-2 hover:td-underline c-pointer"
                    onClick={() => handleLinkClick('privacy-policy')}
                >
                    {langTranslate('Privacy Policy')}
                </span>
                <span className="c-neutral-700 fs-2">{langTranslate('.')}</span>
            </div>
            <Spacer className="pt-3" />
            <Button size="md" type="secondary" full onClick={() => onContinue()}>
                <span className="fs-3 fw-550">{langTranslate('Sign in')}</span>
            </Button>
        </div>
    )

    return (
        <Modal isShowing={isShowing} overlayClassName="z-70" overlayStyle={{ zIndex: 10000 }}>
            <div
                className="bg-white br-4 br-4 o-hidden"
                style={{
                    width: `${isHqPage ? '456px':'400px'}`,
                    minHeight: '456px'
                }}
            >
                <div>{renderPage()}</div>
            </div>
        </Modal>
    )
}

export default ViewContainer
