export default function logError(e) {
    if (typeof newrelic == 'object') {
        newrelic.addPageAction('desktop-error', {
            result: 'error',
            errorObj: e
        })
    }
}

export function logConsole(...msgs) {
    if (process.env.CT_PUBLIC_DEBUG) console.log(...msgs)
}

export function groupCollapsedConsole(msg = 'Group Title', getGroupEnd = false) {
    if (process.env.CT_PUBLIC_DEBUG) {
        if (getGroupEnd) console.groupEnd()
        else console.groupCollapsed(msg)
    }
}

export const CT_SENTRY_ERROR_TYPES = {
    ERROR_BOUNDARY: 'Error Boundary',
    API_ERROR: 'API Error'
}

export function setSentryError(error) {
    const errorObj = JSON.stringify(error)

    const { response: { status, statusText } = {}, config: { url = '', data = {} } = {} } = JSON.parse(errorObj) || {}
    if (typeof newrelic == 'object') {
        newrelic.addPageAction('desktop-error', {
            result: 'api error',
            status: status,
            baseUrl: url.split('?')[0],
            url: url,
            statusText: statusText
        })
    }
}

export function invalidSession(e){
      if(e?.response?.status === 401 && e?.response?.data?.message === 'INVALID_SESSION'){
        window.location.reload()
       }
}