import React from 'react'

import { ReactComponent as Close } from 'assets/icons/modal-close.svg'

function ModalHeader({ title, toggle, subTitle, isHqPage = false }) {
    return (
        <div className="pt-6 pb-6 flex flex-top flex-between">
            <div className="flex flex-column">
                <div className="fs-7 fw-600 c-neutral-900">{title}</div>
                {subTitle && <div className="c-success-500 fs-3 mt-2">{subTitle}</div>}
            </div>
            <div className="br-4 px-1 py-1 hover:bg-neutral-0 flex flex-middle nmx-1" onClick={toggle}>
               {!isHqPage && <Close height={24} width={24} className="hover:bg-neutral-0 c-pointer c-neutral-900" />} 
            </div>
        </div>
    )
}

export default ModalHeader
