import Dropdown from './Dropdown'
import DropdownHeader from './DropdownHeader'
import DropdownItem from './DropdownItem'
import DropdownMenu from './DropdownMenu'
import DropdownSelect from './DropdownSelect'
import './_dropdown.scss'

Dropdown.Header = DropdownHeader
Dropdown.Item = DropdownItem
Dropdown.Menu = DropdownMenu
Dropdown.Select = DropdownSelect

export default Dropdown
