import React from 'react'
import { ReactComponent as UpgradeIcon } from 'assets/icons/upgradeIcon.svg'
import { Spacer } from '@cleartrip/bento'
export const benifits = {
    CT_UPGRADE: {
        text: 'CT_UPGRADE',
        component: (props = {}) => (
            <>
                {props.hideSpacer ? <></> : <Spacer className="ml-1" />}
                <UpgradeIcon {...props} />
            </>
        )
    }
}
export const CT_UPGRADE_SEAT_CALLOUT = 'Choose your favourite seat with CT Upgrade'

export const benefitGroupMap = {
    FLIPKART_PLUS: 'Members',
    MYNTRA_ELITE: 'Elite Insiders',
    MYNTRA_ICON: 'Icon Insiders',
    AXIS_LOYALTY: 'Axis Bank Customers'
}

export const benefitIconGroupMap = {
    FLIPKART_PLUS: 'flipkart',
    MYNTRA_ELITE: 'myntra',
    MYNTRA_ICON: 'myntra',
    AXIS_LOYALTY: 'axis'
}

export const MYNTRA = 'myntra'
export const FLIPKART = 'flipkart'
export const AXIS = 'axis'
