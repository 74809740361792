import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import logError from './logError'
import { DATE_TAB_MATRICES } from 'constants/flightResults'

dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
export function getFormattedDate(date, outputFormat = null, inputFormat = null) {
    let newDate = 'invalid date'
    try {
        newDate = date ? dayjs(date, inputFormat).format(outputFormat) : dayjs().format(outputFormat)
    } catch (e) {
        newDate = 'invalid date'
        logError(e)
    }
    return newDate
}

export function getInitialDateRange({ thisDate: curDate, screenType }) {
    const { totalDays, prevDays } = DATE_TAB_MATRICES[screenType] || DATE_TAB_MATRICES['onward']
    const dDate = dayjs(curDate, 'DD/MM/YYYY')
    const diffDate = dDate.diff(dayjs(), 'day')
    let prevDate = dayjs()
    if (diffDate > 3) {
        prevDate = dayjs(dDate).subtract(prevDays, 'day')
    } else {
        prevDate = dayjs(dDate).subtract(diffDate, 'day')
    }
    const nextDate = dayjs(prevDate).add(totalDays, 'day')
    return `${dayjs(prevDate).format('DD-MM-YYYY')}_${dayjs(nextDate).format('DD-MM-YYYY')}`
}

export function getNewDateRange(dateRange, direction, screenType) {
    const { totalDays } = DATE_TAB_MATRICES[screenType] || DATE_TAB_MATRICES['onward']
    const dates = dateRange.split('_')
    let prevDate
    let nextDate
    if (direction === 'right') {
        prevDate = dayjs(dates[1], 'DD-MM-YYYY')
            .add(1, 'day')
            .format('DD-MM-YYYY')
        nextDate = dayjs(prevDate, 'DD-MM-YYYY')
            .add(totalDays, 'day')
            .format('DD-MM-YYYY')
        return `${prevDate}_${nextDate}`
    }
    const diffDate = dayjs(dates[0], 'DD-MM-YYYY').diff(dayjs(), 'day')
    if (diffDate > totalDays + 1) {
        prevDate = dayjs(dayjs(dates[0], 'DD-MM-YYYY'))
            .subtract(totalDays + 1, 'day')
            .format('DD-MM-YYYY')
    } else {
        prevDate = dayjs(dayjs(dates[0], 'DD-MM-YYYY'))
            .subtract(diffDate, 'day')
            .format('DD-MM-YYYY')
    }
    nextDate = dayjs(prevDate, 'DD-MM-YYYY')
        .add(totalDays, 'day')
        .format('DD-MM-YYYY')
    return `${prevDate}_${nextDate}`
}

export function getInitialMonthRange(departDate) {
    const departureDate = dayjs(departDate, 'DD-MM-YYYY')
    const today = dayjs()
    const diffMonth = departureDate.diff(today, 'month')
    const sub = !diffMonth ? 0 : 1
    const add = 1
    const prevMonth = !diffMonth
        ? dayjs(dayjs(today, 'DD-MM-YYYY'))
              .subtract(sub, 'month')
              .format('DD-MM-YYYY')
        : dayjs(dayjs(departureDate, 'DD-MM-YYYY'))
              .subtract(sub, 'month')
              .format('DD-MM-YYYY')
    const nextMonth = dayjs(dayjs(departureDate, 'DD-MM-YYYY').add(add, 'month'))
        .endOf('month')
        .format('DD-MM-YYYY')
    const defaultDateFormat = `${prevMonth}_${nextMonth}`
    return defaultDateFormat
}
export function getMonthRange(month, direction, monthCount = 3) {
    let prevMonth
    let nextMonth
    let parseMonth = dayjs(getFormattedDate(month, 'DD-MM-YYYY'), 'DD-MM-YYYY')
    const today = dayjs()
    const diffMonth = parseMonth.diff(today, 'month')
    if (direction === 'right') {
        prevMonth = dayjs(dayjs(parseMonth, 'DD-MM-YYYY'))
            .add(2, 'month')
            .format('DD-MM-YYYY')
        nextMonth = dayjs(dayjs(parseMonth, 'DD-MM-YYYY').add(monthCount, 'month'))
            .endOf('month')
            .format('DD-MM-YYYY')
    } else {
        prevMonth = !diffMonth
            ? dayjs(dayjs(today, 'DD-MM-YYYY')).format('DD-MM-YYYY')
            : dayjs(dayjs(parseMonth, 'DD-MM-YYYY')).format('DD-MM-YYYY')
        nextMonth = dayjs(dayjs(parseMonth, 'DD-MM-YYYY').add(1, 'month'))
            .endOf('month')
            .format('DD-MM-YYYY')
    }
    return `${prevMonth}_${nextMonth}`
}

export function minutesToHnM(minutes) {
    const hours = Math.floor(minutes / 60)
    const remMin = minutes % 60
    return remMin > 0 ? `${hours}h ${remMin}m` : `${hours}h`
}

export function dayDiff(days, to) {
    const dDate = dayjs(getFormattedDate(days, 'DD-MM-YYYY'), 'DD-MM-YYYY')
    const toDate = dayjs(getFormattedDate(to, 'DD-MM-YYYY'), 'DD-MM-YYYY')
    const diffDate = dDate.diff(toDate, 'day')
    return diffDate
}

export function differenceInHours(from, to) {
    const H1 = dayjs(from)
    const H2 = dayjs(to)
    const diff = H2.diff(H1, 'hours', true)
    return diff > 0 ? Math.floor(diff) : Math.ceil(diff)
}

export function differenceInMinutes(dirtyDateLeft, dirtyDateRight) {
    let MILLISECONDS_IN_MINUTE = 60000
    var diff = differenceInMilliseconds(dirtyDateLeft, dirtyDateRight) / MILLISECONDS_IN_MINUTE
    return diff > 0 ? Math.floor(diff) : Math.ceil(diff)
}
export function differenceInMilliseconds(dirtyDateLeft, dirtyDateRight) {
    let dateLeft = new Date(dirtyDateLeft)
    let dateRight = new Date(dirtyDateRight)
    return dateLeft.getTime() - dateRight.getTime()
}
export function dateDiff(date1, date2, format = 'DD-MM-YYYY') {
    const dDate1 = dayjs(date1, format)
    const dDate2 = dayjs(date2, format)
    const diffDate = dDate2.diff(dDate1, 'day')
    return diffDate
}

export function yearDiff(date1, date2, format = 'DD-MM-YYYY') {
    const dDate1 = dayjs(date1, format)
    const dDate2 = dayjs(date2, format)
    const diffYear = dDate2.diff(dDate1, 'year', true)
    return diffYear
}

export const getDateAfterNDays = (d, n) => {
    const date = new Date(d)
    date.setDate(date.getDate() + n)
    const formattedDate = dayjs(date).format("DD/MM/YYYY")
    return formattedDate
}