import { personalization } from 'api/flights/results'
import { getCookie, isEmpty } from 'utils/browserHelper'

export const deviceId = async () => {
    const localStorageDeviceId = window?.localStorage?.getItem('dvid')
    const getDeviceIdCookie = getCookie('ct-dvId') || ''
    try {
        if (isEmpty(localStorageDeviceId) && isEmpty(getDeviceIdCookie) || isEmpty(localStorageDeviceId) && !isEmpty(getDeviceIdCookie)) {
            const personalizationResponse = await personalization() 
            const { status, headers } = personalizationResponse
            if (status === 204) {
                window?.localStorage?.setItem('dvid', headers['dvid']) 
                // setCookie('ct-dvId', headers['ct-dvId'], 'y')
            }
        } else if (!isEmpty(localStorageDeviceId) && isEmpty(getDeviceIdCookie)) {
            await personalization(localStorageDeviceId)
        }
    } catch (e) {
        console.error(e)
    }
}