const { resolveKeysInObjectsArrays } = require("utils/browserHelper");

const ClevertapReact = {
    initialize(accountId) {
    try{
        if(!resolveKeysInObjectsArrays(['clevertap', 'account'], window) && !resolveKeysInObjectsArrays(['clevertap', 'event'], window)) {
        window.clevertap = { event: [], profile: [], account: [], onUserLogin: [], notifications: [] }
        window.clevertap.account.push({ id: accountId })
        ;(function() {
            const wzrk = document.createElement('script')
            wzrk.type = 'text/javascript'
            wzrk.async = true
            wzrk.src = `${
                document.location.protocol == 'https:'
                    ? 'https://d2r1yp2w7bby2u.cloudfront.net'
                    : 'http://static.clevertap.com'
            }/js/a.js`
            const s = document.getElementsByTagName('script')[0]
            s.parentNode.insertBefore(wzrk, s)
        })()
        }
    }catch (error) {
        console.log(error);
    }
    },

    event(name, payload) {
        if (payload) {
            window.clevertap.event.push(name, payload)
        } else {
            window.clevertap.event.push(name)
        }
    },

    profile(payload) {
        window.clevertap.profile.push(payload)
    },

    logout() {
        window.clevertap.logout()
    }
}

module.exports = ClevertapReact
