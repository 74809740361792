import { isEmpty } from 'utils/browserHelper'
import Clevertap from 'analytics'

export const sendClevertapUIActionEvent = ({
    actionName,
    actionType = 'BUTTON',
    page,
    urlParam,
    resultsData,
    itinearyData,
    currentPage
}) => {
    let intl = ''
    let journey = ''
    let fullPayload = {
        action_name: actionName,
        action_type: actionType,
        source: 'web',
        domain: window.location.host,
        current_page: page
        //dom_intl: ,
        //journey_type:
    }
    if (currentPage === 'results') {
        const { jsons = {} } = resultsData || {}
        let { searchType: { isIntl = false } = false } = jsons || {}
        intl = isIntl ? 'International' : 'Domestic'

        const { return_date = {} } = urlParam || {}
        journey = !isEmpty(return_date) ? 'RoundTrip' : 'One-way'
        fullPayload = {
            ...fullPayload,
            dom_intl: intl,
            journey_type: journey
        }
    }
    if (currentPage === 'itinerary') {
        const { searchCriteria = {} } = itinearyData || {}
        const { international = false, arrivalDate = false } = searchCriteria || {}
        intl = international ? 'International' : 'Domestic'
        journey = arrivalDate ? 'RoundTrip' : 'OneWay'
        fullPayload = {
            ...fullPayload,
            dom_intl: intl,
            journey_type: journey
        }
    }

    Clevertap.event('Air_UI_Action', fullPayload)
}
