import { DOMAIN } from 'constants/base'
import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'

export const allCountryList = [
    { countryCode: 'AF', en: 'Afghanistan', ar: 'أفغانستان' },
    { countryCode: 'AL', en: 'Albania', ar: 'ألبانيا' },
    { countryCode: 'DZ', en: 'Algeria', ar: 'الجزائر' },
    { countryCode: 'AS', en: 'American Samoa', ar: 'ساموا الأمريكية' },
    { countryCode: 'AD', en: 'Andorra', ar: 'أندورا' },
    { countryCode: 'AO', en: 'Angola', ar: 'أنغولا' },
    { countryCode: 'AI', en: 'Anguilla', ar: 'أنغيلا' },
    { countryCode: 'AG', en: 'Antigua and Barbuda', ar: 'أنتيغوا وبربودا' },
    { countryCode: 'AR', en: 'Argentina', ar: 'الأرجنتين' },
    { countryCode: 'AM', en: 'Armenia', ar: 'أرمينيا' },
    { countryCode: 'AW', en: 'Aruba', ar: 'أروبا' },
    { countryCode: 'AU', en: 'Australia', ar: 'أستراليا' },
    { countryCode: 'AT', en: 'Austria', ar: 'النمسا' },
    { countryCode: 'AZ', en: 'Azerbaijan', ar: 'أذربيجان' },
    { countryCode: 'BS', en: 'Bahamas', ar: 'جزر البهاما' },
    { countryCode: 'BH', en: 'Bahrain', ar: 'البحرين' },
    { countryCode: 'BD', en: 'Bangladesh', ar: 'بنغلاديش' },
    { countryCode: 'BB', en: 'Barbados', ar: 'بربادوس' },
    { countryCode: 'BY', en: 'Belarus', ar: 'بيلاروس' },
    { countryCode: 'BE', en: 'Belgium', ar: 'بلجيكا' },
    { countryCode: 'BZ', en: 'Belize', ar: 'بليز' },
    { countryCode: 'BJ', en: 'Benin', ar: 'بنن' },
    { countryCode: 'BM', en: 'Bermuda', ar: 'برمودا' },
    { countryCode: 'BT', en: 'Bhutan', ar: 'بوتان' },
    { countryCode: 'BO', en: 'Bolivia', ar: 'بوليفيا' },
    { countryCode: 'BA', en: 'Bosnia and Herzegovina', ar: 'البوسنة والهرسك' },
    { countryCode: 'BW', en: 'Botswana', ar: 'بوتسوانا' },
    { countryCode: 'BR', en: 'Brazil', ar: 'البرازيل' },
    { countryCode: 'VG', en: 'British Virgin Islands', ar: 'جزر فيرجن البريطانية' },
    { countryCode: 'BN', en: 'Brunei', ar: 'بروناي' },
    { countryCode: 'BG', en: 'Bulgaria', ar: 'بلغاريا' },
    { countryCode: 'BF', en: 'Burkina Faso', ar: 'بوركينا فاسو' },
    { countryCode: 'BI', en: 'Burundi', ar: 'بوروندي' },
    { countryCode: 'KH', en: 'Cambodia', ar: 'كمبوديا' },
    { countryCode: 'CM', en: 'Cameroon', ar: 'الكاميرون' },
    { countryCode: 'CA', en: 'Canada', ar: 'كندا' },
    { countryCode: 'CV', en: 'Cape Verde', ar: 'الرأس الأخضر' },
    { countryCode: 'KY', en: 'Cayman Islands', ar: 'جزر كايمان' },
    { countryCode: 'CF', en: 'Central African Republic', ar: 'جمهورية افريقيا الوسطى' },
    { countryCode: 'TD', en: 'Chad', ar: 'تشاد' },
    { countryCode: 'CL', en: 'Chile', ar: 'شيلي' },
    { countryCode: 'CN', en: 'China', ar: 'الصين' },
    { countryCode: 'CX', en: 'Christmas Island', ar: 'جزيرة الكريسماس' },
    { countryCode: 'CC', en: 'Cocos (Keeling) Islands', ar: 'جزر كوكوس (كيلينغ)' },
    { countryCode: 'CO', en: 'Colombia', ar: 'كولومبيا' },
    { countryCode: 'KM', en: 'Comoros', ar: 'جزر القمر' },
    { countryCode: 'CG', en: 'Congo', ar: 'الكونغو' },
    { countryCode: 'CK', en: 'Cook Islands', ar: 'جزر كوك' },
    { countryCode: 'CR', en: 'Costa Rica', ar: 'كوستا ريكا' },
    { countryCode: 'CI', en: "Cote d'Ivoire", ar: 'كوت ديفوار' },
    { countryCode: 'HR', en: 'Croatia', ar: 'كرواتيا' },
    { countryCode: 'CY', en: 'Cyprus', ar: 'قبرص' },
    { countryCode: 'CZ', en: 'Czech Republic', ar: 'الجمهورية التشيكية' },
    { countryCode: 'DK', en: 'Denmark', ar: 'الدنمارك' },
    { countryCode: 'DJ', en: 'Djibouti', ar: 'جيبوتي' },
    { countryCode: 'DM', en: 'Dominica', ar: 'دومينيكا' },
    { countryCode: 'DO', en: 'Dominican Republic', ar: 'جمهورية الدومنيكان' },
    { countryCode: 'EC', en: 'Ecuador', ar: 'إكوادور' },
    { countryCode: 'EG', en: 'Egypt', ar: 'مصر' },
    { countryCode: 'SV', en: 'El Salvador', ar: 'السلفادور' },
    { countryCode: 'GQ', en: 'Equatorial Guinea', ar: 'غينيا الإستوائية' },
    { countryCode: 'ER', en: 'Eritrea', ar: 'إريتريا' },
    { countryCode: 'EE', en: 'Estonia', ar: 'إستونيا' },
    { countryCode: 'ET', en: 'Ethiopia', ar: 'أثيوبيا' },
    { countryCode: 'FK', en: 'Falkland Islands (Islas Malvinas)', ar: 'جزر فوكلاند (جزر مالفيناس)' },
    { countryCode: 'FO', en: 'Faroe Islands', ar: 'جزر فاروس' },
    { countryCode: 'FJ', en: 'Fiji', ar: 'فيجي' },
    { countryCode: 'FI', en: 'Finland', ar: 'فنلندا' },
    { countryCode: 'FR', en: 'France', ar: 'فرنسا' },
    { countryCode: 'GF', en: 'French Guiana', ar: 'غيانا الفرنسية' },
    { countryCode: 'PF', en: 'French Polynesia', ar: 'بولينيزيا الفرنسية' },
    { countryCode: 'GA', en: 'Gabon', ar: 'غابون' },
    { countryCode: 'GM', en: 'Gambia', ar: 'غامبيا' },
    { countryCode: 'GE', en: 'Georgia', ar: 'جورجيا' },
    { countryCode: 'DE', en: 'Germany', ar: 'ألمانيا' },
    { countryCode: 'GH', en: 'Ghana', ar: 'غانا' },
    { countryCode: 'GI', en: 'Gibraltar', ar: 'جبل طارق' },
    { countryCode: 'GR', en: 'Greece', ar: 'اليونان' },
    { countryCode: 'GL', en: 'Greenland', ar: 'الأرض الخضراء' },
    { countryCode: 'GD', en: 'Grenada', ar: 'غرينادا' },
    { countryCode: 'GP', en: 'Guadeloupe', ar: 'جوادلوب' },
    { countryCode: 'GU', en: 'Guam', ar: 'غوام' },
    { countryCode: 'GT', en: 'Guatemala', ar: 'غواتيمالا' },
    { countryCode: 'xx', en: 'Guernsey', ar: 'غيرنسي' },
    { countryCode: 'GN', en: 'Guinea', ar: 'غينيا' },
    { countryCode: 'GW', en: 'Guinea-Bissau', ar: 'غينيا - بيساو' },
    { countryCode: 'GY', en: 'Guyana', ar: 'غيانا' },
    { countryCode: 'HT', en: 'Haiti', ar: 'هايتي' },
    { countryCode: 'VA', en: 'Holy See (Vatican City)', ar: 'الكرسي الرسولي (الفاتيكان)' },
    { countryCode: 'HN', en: 'Honduras', ar: 'هندوراس' },
    { countryCode: 'HK', en: 'Hong Kong', ar: 'هونغ كونغ' },
    { countryCode: 'HU', en: 'Hungary', ar: 'هنغاريا' },
    { countryCode: 'IS', en: 'Iceland', ar: 'أيسلندا' },
    { countryCode: 'IN', en: 'India', ar: 'الهند' },
    { countryCode: 'ID', en: 'Indonesia', ar: 'إندونيسيا' },
    { countryCode: 'IQ', en: 'Iraq', ar: 'العراق' },
    { countryCode: 'IE', en: 'Ireland', ar: 'أيرلندا' },
    { countryCode: 'IM', en: 'Isle of Man', ar: 'جزيرة آيل أوف مان' },
    { countryCode: 'IL', en: 'Israel', ar: 'إسرائيل' },
    { countryCode: 'IT', en: 'Italy', ar: 'إيطاليا' },
    { countryCode: 'JM', en: 'Jamaica', ar: 'جامايكا' },
    { countryCode: 'JP', en: 'Japan', ar: 'اليابان' },
    { countryCode: 'JE', en: 'Jersey', ar: 'جيرسي' },
    { countryCode: 'JO', en: 'Jordan', ar: 'الأردن' },
    { countryCode: 'KZ', en: 'Kazakhstan', ar: 'كازاخستان' },
    { countryCode: 'KE', en: 'Kenya', ar: 'كينيا' },
    { countryCode: 'KI', en: 'Kiribati', ar: 'كيريباتي' },
    { countryCode: 'KW', en: 'Kuwait', ar: 'الكويت' },
    { countryCode: 'KG', en: 'Kyrgyzstan', ar: 'قيرغيزستان' },
    { countryCode: 'LA', en: 'Laos', ar: 'لاوس' },
    { countryCode: 'LV', en: 'Latvia', ar: 'لاتفيا' },
    { countryCode: 'LB', en: 'Lebanon', ar: 'لبنان' },
    { countryCode: 'LS', en: 'Lesotho', ar: 'ليسوتو' },
    { countryCode: 'LR', en: 'Liberia', ar: 'ليبيريا' },
    { countryCode: 'LY', en: 'Libya', ar: 'ليبيا' },
    { countryCode: 'LI', en: 'Liechtenstein', ar: 'ليختنشتاين' },
    { countryCode: 'LT', en: 'Lithuania', ar: 'ليتوانيا' },
    { countryCode: 'LU', en: 'Luxembourg', ar: 'لوكسمبورغ' },
    { countryCode: 'MK', en: 'Macedonia', ar: 'مقدونيا' },
    { countryCode: 'MG', en: 'Madagascar', ar: 'مدغشقر' },
    { countryCode: 'MW', en: 'Malawi', ar: 'ملاوي' },
    { countryCode: 'MY', en: 'Malaysia', ar: 'ماليزيا' },
    { countryCode: 'MV', en: 'Maldives', ar: 'ملديف' },
    { countryCode: 'ML', en: 'Mali', ar: 'مالي' },
    { countryCode: 'MT', en: 'Malta', ar: 'مالطا' },
    { countryCode: 'MH', en: 'Marshall Islands', ar: 'جزر مارشال' },
    { countryCode: 'MQ', en: 'Martinique', ar: 'مارتينيك' },
    { countryCode: 'MR', en: 'Mauritania', ar: 'موريتانيا' },
    { countryCode: 'MU', en: 'Mauritius', ar: 'موريشيوس' },
    { countryCode: 'YT', en: 'Mayotte', ar: 'مايوت' },
    { countryCode: 'MX', en: 'Mexico', ar: 'المكسيك' },
    { countryCode: 'FM', en: 'Micronesia', ar: 'ميكرونيزيا' },
    { countryCode: 'MD', en: 'Moldova', ar: 'مولدوفا' },
    { countryCode: 'MC', en: 'Monaco', ar: 'موناكو' },
    { countryCode: 'MN', en: 'Mongolia', ar: 'منغوليا' },
    { countryCode: 'ME', en: 'Montenegro', ar: 'الجبل الأسود' },
    { countryCode: 'MS', en: 'Montserrat', ar: 'مونتسيرات' },
    { countryCode: 'MA', en: 'Morocco', ar: 'المغرب' },
    { countryCode: 'MZ', en: 'Mozambique', ar: 'موزامبيق' },
    { countryCode: 'MM', en: 'Myanmar (Burma)', ar: 'ميانمار (بورما)' },
    { countryCode: 'NA', en: 'Namibia', ar: 'ناميبيا' },
    { countryCode: 'NR', en: 'Nauru', ar: 'ناورو' },
    { countryCode: 'NP', en: 'Nepal', ar: 'نيبال' },
    { countryCode: 'NL', en: 'Netherlands', ar: 'هولندا' },
    { countryCode: 'AN', en: 'Netherlands Antilles', ar: 'جزر الأنتيل الهولندية' },
    { countryCode: 'NC', en: 'New Caledonia', ar: 'كاليدونيا الجديدة' },
    { countryCode: 'NZ', en: 'New Zealand', ar: 'نيوزيلاندا' },
    { countryCode: 'NI', en: 'Nicaragua', ar: 'نيكاراغوا' },
    { countryCode: 'NE', en: 'Niger', ar: 'النيجر' },
    { countryCode: 'NG', en: 'Nigeria', ar: 'نيجيريا' },
    { countryCode: 'NU', en: 'Niue', ar: 'نيوي' },
    { countryCode: 'NF', en: 'Norfolk Island', ar: 'جزيرة نورفولك' },
    { countryCode: 'MP', en: 'Northern Mariana Islands', ar: 'جزر مريانا الشمالية' },
    { countryCode: 'NO', en: 'Norway', ar: 'النرويج' },
    { countryCode: 'OM', en: 'Oman', ar: 'سلطنة عمان' },
    { countryCode: 'PK', en: 'Pakistan', ar: 'باكستان' },
    { countryCode: 'PW', en: 'Palau', ar: 'بالاو' },
    { countryCode: 'PS', en: 'Palestine', ar: 'فلسطين' },
    { countryCode: 'PA', en: 'Panama', ar: 'بنما' },
    { countryCode: 'PG', en: 'Papua New Guinea', ar: 'بابوا غينيا الجديدة' },
    { countryCode: 'PY', en: 'Paraguay', ar: 'باراغواي' },
    { countryCode: 'PE', en: 'Peru', ar: 'بيرو' },
    { countryCode: 'PH', en: 'Philippines', ar: 'فيلبيني' },
    { countryCode: 'PN', en: 'Pitcairn Islands', ar: 'جزر بيتكيرن' },
    { countryCode: 'PL', en: 'Poland', ar: 'بولندا' },
    { countryCode: 'PT', en: 'Portugal', ar: 'البرتغال' },
    { countryCode: 'PR', en: 'Puerto Rico', ar: 'بورتوريكو' },
    { countryCode: 'QA', en: 'Qatar', ar: 'دولة قطر' },
    { countryCode: 'RE', en: 'Reunion', ar: 'جمع شمل' },
    { countryCode: 'RO', en: 'Romania', ar: 'رومانيا' },
    { countryCode: 'RU', en: 'Russia', ar: 'روسيا' },
    { countryCode: 'RW', en: 'Rwanda', ar: 'رواندا' },
    { countryCode: 'KN', en: 'Saint Kitts and Nevis', ar: 'سانت كيتس ونيفيس' },
    { countryCode: 'LC', en: 'Saint Lucia', ar: 'القديسة لوسيا' },
    { countryCode: 'PM', en: 'Saint Pierre and Miquelon', ar: 'سانت بيير وميكلون' },
    { countryCode: 'VC', en: 'Saint Vincent and the Grenadines', ar: 'سانت فنسنت وجزر غرينادين' },
    { countryCode: 'SM', en: 'San Marino', ar: 'سان مارينو' },
    { countryCode: 'ST', en: 'Sao Tome and Principe', ar: 'سان تومي وبرينسيبي' },
    { countryCode: 'SA', en: 'Saudi Arabia', ar: 'المملكة العربية السعودية' },
    { countryCode: 'SN', en: 'Senegal', ar: 'السنغال' },
    { countryCode: 'RS', en: 'Serbia', ar: 'صربيا' },
    { countryCode: 'SC', en: 'Seychelles', ar: 'سيشيل' },
    { countryCode: 'SL', en: 'Sierra Leone', ar: 'سيرا ليون' },
    { countryCode: 'SG', en: 'Singapore', ar: 'سنغافورة' },
    { countryCode: 'SK', en: 'Slovakia', ar: 'سلوفاكيا' },
    { countryCode: 'SI', en: 'Slovenia', ar: 'سلوفينيا' },
    { countryCode: 'SB', en: 'Solomon Islands', ar: 'جزر سليمان' },
    { countryCode: 'SO', en: 'Somalia', ar: 'الصومال' },
    { countryCode: 'ZA', en: 'South Africa', ar: 'جنوب أفريقيا' },
    { countryCode: 'KR', en: 'South Korea', ar: 'كوريا الجنوبية' },
    { countryCode: 'ES', en: 'Spain', ar: 'إسبانيا' },
    { countryCode: 'LK', en: 'Sri Lanka', ar: 'سيريلانكا' },
    { countryCode: 'SR', en: 'Suriname', ar: 'سورينام' },
    { countryCode: 'SJ', en: 'Svalbard and Jan Mayen Islands', ar: 'جزر سفالبارد وجان ماين' },
    { countryCode: 'SZ', en: 'Swaziland', ar: 'سوازيلند' },
    { countryCode: 'SE', en: 'Sweden', ar: 'السويد' },
    { countryCode: 'CH', en: 'Switzerland', ar: 'سويسرا' },
    { countryCode: 'TW', en: 'Taiwan', ar: 'تايوان' },
    { countryCode: 'TJ', en: 'Tajikistan', ar: 'طاجيكستان' },
    { countryCode: 'TZ', en: 'Tanzania', ar: 'تنزانيا' },
    { countryCode: 'TH', en: 'Thailand', ar: 'تايلاند' },
    { countryCode: 'TG', en: 'Togo', ar: 'توجو' },
    { countryCode: 'TK', en: 'Tokelau', ar: 'توكيلاو' },
    { countryCode: 'TO', en: 'Tonga', ar: 'تونغا' },
    { countryCode: 'TT', en: 'Trinidad and Tobago', ar: 'ترينداد وتوباغو' },
    { countryCode: 'TN', en: 'Tunisia', ar: 'تونس' },
    { countryCode: 'TR', en: 'Turkey', ar: 'ديك رومى' },
    { countryCode: 'TM', en: 'Turkmenistan', ar: 'تركمانستان' },
    { countryCode: 'TC', en: 'Turks and Caicos Islands', ar: 'جزر تركس وكايكوس' },
    { countryCode: 'TV', en: 'Tuvalu', ar: 'توفالو' },
    { countryCode: 'UG', en: 'Uganda', ar: 'أوغندا' },
    { countryCode: 'UA', en: 'Ukraine', ar: 'أوكرانيا' },
    { countryCode: 'AE', en: 'United Arab Emirates', ar: 'الإمارات العربية المتحدة' },
    { countryCode: 'GB', en: 'United Kingdom', ar: 'المملكة المتحدة' },
    { countryCode: 'US', en: 'United States', ar: 'الولايات المتحدة' },
    { countryCode: 'UY', en: 'Uruguay', ar: 'أوروغواي' },
    { countryCode: 'UZ', en: 'Uzbekistan', ar: 'أوزبكستان' },
    { countryCode: 'VU', en: 'Vanuatu', ar: 'فانواتو' },
    { countryCode: 'VE', en: 'Venezuela', ar: 'فنزويلا' },
    { countryCode: 'VN', en: 'Vietnam', ar: 'فيتنام' },
    { countryCode: 'VI', en: 'Virgin Islands', ar: 'جزر فيرجن' },
    { countryCode: 'WF', en: 'Wallis and Futuna', ar: 'واليس وفوتونا' },
    { countryCode: 'EH', en: 'Western Sahara', ar: 'الصحراء الغربية' },
    { countryCode: 'WS', en: 'Western Samoa', ar: 'ساموا الغربية' },
    { countryCode: 'YE', en: 'Yemen', ar: 'اليمن' },
    { countryCode: 'ZR', en: 'Zaire (Dem Rep of Congo)', ar: 'زائير (جمهورية الكونغو الديمقراطية)' },
    { countryCode: 'ZM', en: 'Zambia', ar: 'زامبيا' },
    { countryCode: 'ZW', en: 'Zimbabwe', ar: 'زمبابوي' }
]
const nationalIdList = [
    { countryCode: 'IN', en: 'India', ar: 'الهند' },
    { countryCode: 'SA', en: 'Saudi Arabia', ar: 'المملكة العربية السعودية' },
    { countryCode: 'AE', en: 'United Arab Emirates', ar: 'الإمارات العربية المتحدة' },
    { countryCode: 'BH', en: 'Bahrain', ar: 'البحرين' },
    { countryCode: 'KW', en: 'Kuwait', ar: 'الكويت' },
    { countryCode: 'QA', en: 'Qatar', ar: 'دولة قطر' },
    { countryCode: 'OM', en: 'Oman', ar: 'سلطنة عمان' },
    { countryCode: 'US', en: 'United States', ar: 'الولايات المتحدة' }
]
const firstPreferredCountry = nationalIdList.find(countryObj => {
    return (
        countryObj.countryCode === DOMAIN_COUNTRY_MAP[DOMAIN] ||
        (countryObj.countryCode === 'US' && DOMAIN_COUNTRY_MAP[DOMAIN] === 'ME')
    )
})

export const nationalIdPrefferedList = firstPreferredCountry
    ? [
          firstPreferredCountry,
          ...nationalIdList.filter(countryObj => {
              return countryObj.countryCode !== firstPreferredCountry.countryCode
          })
      ]
    : [...nationalIdList]
