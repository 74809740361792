import React from 'react'
import ReactDOM from 'react-dom'
// import OutsideClickHandler from '../OutsideClickHandler'
import Overlay from '../Overlay'

const Modal = ({
    className,
    children,
    isShowing,
    toggle,
    overlayStyle,
    overlayClassName,
    isCross = false,
    onOverlayClick = () => {},
    ...otherProps
}) =>
    isShowing &&
    ReactDOM.createPortal(
        <Overlay style={overlayStyle} className={overlayClassName} onOverlayClick={onOverlayClick}>
            <div className="p-relative">
                {children}
                {isCross && (
                    <div
                        onClick={toggle}
                        style={{ top: '-44px', width: '32px' }}
                        className="c-pointer bg-white br-100 flex flex-center flex-middle h-8 p-absolute r-0"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L12 12M12 12L6 18M12 12L6 6M12 12L18 18"
                                stroke="#1A1A1A"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </Overlay>,
        document.body
    )

export default Modal
