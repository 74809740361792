import React, { useEffect, useState } from 'react'
import useModal from 'lib/Modal/useModal'
import ViewContainer from '../CTUserProfile/ViewContainer'
import USER_PROFILE_PAGE_CONTANTS from '../CTUserProfile/UserProfileConstants'
import { isUserSignedIn } from 'utils/browserHelper'

const HqPage = () => {
    const { isShowing, toggle } = useModal()
    const [page, setPage] = useState(null)
    useEffect(() => {
        setPage(USER_PROFILE_PAGE_CONTANTS.SIGNIN_PAGE)
    }, [])
    const handleIsShowing = () => {
        toggle()
    }
    useEffect(()=>{
         const isUserSigned =  isUserSignedIn()
        if(isUserSigned){
             window.location.href = '/hq'
        }
    },[])

    return (
            <ViewContainer
                isHqPage={true}
                page={page}
                isShowing={true}
                toggle={toggle}
                handleIsShowing={handleIsShowing}
            />
    )
}
export default HqPage
