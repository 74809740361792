import { createContext } from 'react'

export const FlightsDataContext = createContext([{}, () => {}])

export const FiltersContext = createContext([{}, () => {}])

export const ResultsDataContext = createContext([{}, () => {}])

export const OrderedFlightIdsContext = createContext([[], () => {}])

export const TuppleSorterContext = createContext([[], () => {}])

export const CurrencyContext = createContext([{}, () => {}])

export const SearchQueryContext = createContext([[], () => {}])

export const UserContext = createContext([{}, () => {}])

export const UserProfileContext = createContext([{}, () => {}])

export const masterTuppleContext = createContext([{}, () => {}])

export const ClevertapDataContext = createContext([{}, () => {}])

export const ProfileDataContext = createContext([{}, () => {}])

export const CtUpgradeContext = createContext([{}, () => {}])

export const OfferContext = createContext([{}, () => {}])