import React, { useState, useEffect } from 'react'
import CsrRouter from 'router'
// import lm from 'locale' // locale or language map
import {
    CurrencyContext,
    UserContext,
    UserProfileContext,
    ProfileDataContext,
    CtUpgradeContext,
    OfferContext
} from 'context/flights/results'
import { userLoggedInObj } from 'utils/flights/results'
import { logConsole, groupCollapsedConsole } from 'utils/logError'
import { getScript, GACode, getCookie, delete_cookie,isUserSignedIn, loadRavenSDK } from 'utils/browserHelper'
import { getUserProfileDetails } from 'api/flights/results'
import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'

import { DOMAIN } from 'constants/base'
import {loginPush, responseMapper, sendProfileData} from 'analytics/clevartapProfile'
import { deviceId } from 'utils/deviceIdUtils'

function App() {
    const currencyHook = useState([])
    const userDataHook = useState(() => userLoggedInObj())
    const userProfileHook = useState({})
    const profileHook = useState({})
    const ctUpgradeHook = useState({})
    const ctEnv = process.env.CT_ENV === 'production' ? 'prod' : 'staging'
    // once the raven URLs are stored in config and this variable ravenSDKUrl will be removed from here
    // const ravenSDKUrl = process.env.CT_ENV !== 'production' 
    //     ? "https://fastui.cltpstatic.com/raw/upload/resources-qa/raven-web-sdk/static/js/bundle.d8ed4a33.js" : ''
    const [offerHook, setOfferHook] = useState({})
    const offerJSON = async () => {
        await getScript(
            `https://www.cleartrip.sa/offermgmt/offerJson/${ctEnv}/offerSlot.js?` + new Date().getTime(),
            (data) => {
                setOfferHook(data) // Hack for BBD
            }
        )
    }
    useEffect(() => {
    
        if(getCookie('ct-pwa')){
            delete_cookie('ct-pwa')
          }
            if (DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
                offerJSON()
                    GACode()
                    if(typeof window.ravenWebManager === 'object'){
                        console.log("Raven loaded")
                    }
                    else{
                        console.log("Failed to load Raven SDK")
                    } 
                // loadRavenSDK(ravenSDKUrl)
                //     .then(()=>{console.log("Raven loaded")})
                //     .catch((e)=>{console.log("Failed to load Raven SDK")})
            }
            async function getUserProfile() {
                try {
                    const getResponse = await getUserProfileDetails()
                    const { data, status } = getResponse || {}
                    if (status == 200) {
                        let finalPayload = responseMapper(data)
                        sendProfileData(finalPayload)
                    }
                } catch (e) {
                    console.log('User profile details api error', e)
                }
            }
            if (isUserSignedIn() && !loginPush) {
                getUserProfile()
            }
            deviceId()
    },[])
    groupCollapsedConsole('Runtime Config')
    logConsole('NODE_ENV - ', process.env.NODE_ENV)
    logConsole('CT_ENV   - ', process.env.CT_ENV)
    logConsole('LANG     - ', process.env.LOCALE_LANG)
    logConsole('TEMPLATE - ', process.env.TEMPLATE)
    logConsole('BITBUCKET_BRANCH - ', process.env.BITBUCKET_BRANCH)
    logConsole('BITBUCKET_BUILD_NUMBER - ', process.env.BITBUCKET_BUILD_NUMBER)
    logConsole('BITBUCKET_COMMIT - ', process.env.BITBUCKET_COMMIT)
    logConsole('CONTENT_DIR - ', process.env.CONTENT_DIR)
    logConsole('CT_PUBLIC_USER_API_BASE_URL - ', process.env.CT_PUBLIC_USER_API_BASE_URL)
    groupCollapsedConsole('', true)
    return (
        <div className="App flex flex-column" style={{ minHeight:'100vh',width:'100%'}}>
            <ProfileDataContext.Provider value={profileHook}>
                <UserContext.Provider value={userDataHook}>
                    <OfferContext.Provider value={offerHook}>
                        <UserProfileContext.Provider value={userProfileHook}>
                            <CurrencyContext.Provider value={currencyHook}>
                                <CtUpgradeContext.Provider value={ctUpgradeHook}>
                                    <CsrRouter />
                                </CtUpgradeContext.Provider>
                            </CurrencyContext.Provider>
                        </UserProfileContext.Provider>
                    </OfferContext.Provider>
                </UserContext.Provider>
            </ProfileDataContext.Provider>
        </div>
    )
}

export default App
