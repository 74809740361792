import React, { Children } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Dots } from '@cleartrip/bento'

const DropdownMenu = ({ className, children, active, width, loading, position, distance, style }) => {
    const menuClassName = classNames(
        'bg-white br-4 elevation-5 p-1 p-absolute mt-1 z-50',
        {
            'r-0 nmr-3': position === 'right',
            'l-0': position === 'left'
        },
        className
    )
    const leftPos = {
        left: `${distance}px`,
        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.24)',
        minWidth: `${width}px`,
        ...style
    }
    const rightPos = {
        right: `${distance}px`,
        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.24)',
        minWidth: `${width}px`,
        ...style
    }
    return (
        <>
            {active && (
                <div className={menuClassName} style={position === 'left' ? leftPos : rightPos}>
                    <ul>
                        {loading ? (
                            <div className="px-10 py-5 flex flex-center flex-middle">
                                <Dots color="#36c" />
                            </div>
                        ) : (
                            Children.map(children, child => {
                                if (child !== null) return React.cloneElement(child)
                            })
                        )}
                    </ul>
                </div>
            )}
        </>
    )
}

DropdownMenu.defaultProps = {
    width: 104,
    loading: false,
    position: 'left',
    className: '',
    distance: 0
}

DropdownMenu.propType = {
    width: PropTypes.number,
    loading: PropTypes.bool,
    position: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    distance: PropTypes.number
}

export default DropdownMenu
