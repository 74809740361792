import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/index.scss'
import * as serviceWorker from 'utils/serviceWorkerHelper'
import App from './App'
// const tagManagerArgs = {
//     gtmId: 'GTM-T9S432'
// }
// TagManager.initialize(tagManagerArgs)
ReactDOM.render(<App />, document.getElementById('root'))



// Service Worker helper added to utils
serviceWorker.unregister()
