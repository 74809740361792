import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@cleartrip/bento'
import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg'
const ErrorIconBBD = 'https://fastui.cltpstatic.com/image/upload/f_auto,q_auto,w_235,h_122,dpr_2/resources/images/newError.png'
const Error = props => {
    const { title, subTitle, buttonText, onButtonClick } = props
    return (
        <>
            <div className="flex-column flex-center ta-center align-middle" style={{ width: title ? '512px' : 'auto' }}>
                {title === 'Whoops!' ? <img src={ErrorIconBBD} alt="error" /> : <ErrorIcon />}
                <div className="flex flex-column flex-middle">
                {title && <h3 className="fs-7 fw-600 mt-7 c-neutral-900 lh-title">{title}</h3>}
                    <p className="fs-4 mt-4 c-neutral-600 lh-copy" style={{ width: '70%' }}>
                        {subTitle}
                    </p>

                    {onButtonClick && (
                        <Button type="secondary" className="mt-12 w-60p" size="sm" onClick={onButtonClick}>
                            {buttonText}
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}

Error.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func
}
Error.defaultProps = {
    title: '',
    subTitle: '',
    buttonText: ''
}

export default Error
