import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import RootRouter from 'router/RootRouter'

function CsrRouter(props) {
    return (
        <Router>
            <RootRouter />
        </Router>
    )
}

export default CsrRouter
