import React from 'react'
import { Switch, Route } from 'react-router-dom'
import flightRoutes from './flightRoutes.config'
import Error from 'components/Error'
function FlightRoutes(props) {
    const error= {
        title: "Whatever you're looking for, isn't here",
        subTitle:
            "Sorry, this resource does not exist on our servers. You may have followed an outdated link or entered an incorrect url.",
        buttonText: "Search for flights",
        onButtonClick: () => window.location.href='/'
    }
    const NotFound = () =>{
        return (
            <div className='container'>
                <Error {...error} />
            </div>
           
        )
    } 
    
    // return (
    //     <Switch>
    //         <Route exact path="/" component={Home} />
    //         <Route exact path="/flights" component={Home} />
    //         <Route exact path="/flights/results" component={Results} />
    //     </Switch>
    // )
    return (
        <Switch>
            {flightRoutes.map((route, i) => (
                <Route {...route} key={i} />
            ))}
             <Route component={NotFound} />
        </Switch>
    )
}

export default FlightRoutes
